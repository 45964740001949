import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import useContentful from "../hooks/use-contenful";
import BlockLeftImage from "../components/BlockLeftImage";
import BlockRightImage from "../components/BlockRightImage";
import ModalFixed from "../components/ModalFixed";
import Block3ColumnsText from "../components/Block3ColumnsText";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";

const query = `
query 
{
    videoTemplate(id: "Okkn1wjLyVJQZRlBZxl0B") {
      contentBlock1{json}
      imageBlock1{url}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}
      imageBlock7{url, title}
      contentBlock7{json}
      imageBlock8{url, title}
      contentBlock8{json}
      contentBlock9{json}
    }
  
}
`;

function Acercade() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/acercade">Acerca de nosotros</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="relative ">
        <div className="layout8  ">
          {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
        </div>
        <div className="padding"></div>
        <ModalFixed
          title="Conoce nuestra historia 💙"
          content={
            <>
              <iframe
                allowfullscreen=""
                frameborder="0"
                height="700"
                mozallowfullscreen=""
                src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=15c2kS_nfTkn7O4XqbtO6q7sY_tMirfPwyMp1Jb6bcpA&amp;font=Bitter-Raleway&amp;lang=en&amp;initial_zoom=2&amp;height=650"
                webkitallowfullscreen=""
                width="100%"
              ></iframe>
            </>
          }
          color="#1366a0"
        ></ModalFixed>
      </div>

      <div className="bluebg padding">
        <Block3ColumnsText
          col1={documentToReactComponents(
            data.videoTemplate.contentBlock2.json
          )}
          col2={documentToReactComponents(
            data.videoTemplate.contentBlock3.json
          )}
          col3={documentToReactComponents(
            data.videoTemplate.contentBlock4.json
          )}
        ></Block3ColumnsText>
      </div>

      <div className="relative ">
     
          <center>
            <h2 className="padding2">Líneas estratégicas</h2>
          </center>
          <BlockLeftImage
            src={data.videoTemplate.imageBlock5.url}
            alt={data.videoTemplate.imageBlock5.title}
            content={documentToReactComponents(
              data.videoTemplate.contentBlock5.json
            )}
          ></BlockLeftImage>
          <BlockRightImage
            src={data.videoTemplate.imageBlock6.url}
            alt={data.videoTemplate.imageBlock6.title}
            content={documentToReactComponents(
              data.videoTemplate.contentBlock6.json
            )}
          ></BlockRightImage>

          <BlockLeftImage
            src={data.videoTemplate.imageBlock7.url}
            alt={data.videoTemplate.imageBlock7.title}
            content={documentToReactComponents(
              data.videoTemplate.contentBlock7.json
            )}
          ></BlockLeftImage>
     

        <ModalFixed
          title="Historia de conformación de nuestras carreras 🧩"
          content={
            <>
              <iframe
                allowfullscreen=""
                frameborder="0"
                height="700"
                mozallowfullscreen=""
                src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1j76kXBC5SXwYKugt-oR6MTauJSaf06VlRkQAjCoUHG8&amp;font=Bitter-Raleway&amp;lang=en&amp;initial_zoom=2&amp;height=650"
                webkitallowfullscreen=""
                width="100%"
              ></iframe>
            </>
          }
          color="#1366a0"
        ></ModalFixed>
      </div>

      <div className="padding bienvenida-rector graybg">
        <div className="layout8">
          {" "}
          {documentToReactComponents(data.videoTemplate.contentBlock8.json)}
        </div>

        <BlockRightImage
          src={data.videoTemplate.imageBlock8.url}
          alt={data.videoTemplate.imageBlock8.title}
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock9.json)}

            </>
          }
        ></BlockRightImage>
      </div>
    </>
  );
}

export default Acercade;
