import BannerGeneral from "../components/BannerGeneral";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BlockImageCabecera from "../components/BlockImageCabecera";
import useContentful from "../hooks/use-contenful";
import Notification from "../components/Notification";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import ConectUs from "../components/ConectUs";
import PosgradoPagos from "../components/PosgradoPagos";
import PosgradoOffer from "../components/PosgradoOffer";
import { PosgradoPostulacionFixed } from "../components/Pasos";
import Modal from "../components/Modal";
import HubspotForm from "react-hubspot-form";

const query = `
query 
{
    videoTemplate(id: "41Gw5Tkt6KoUDf7xO36UOH") {
 
      imageBlock1{url}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      imageBlock3{url}
      galleryCollection{
        items{url title}
         }

         gallery2Collection{
          items{url title}
           }
    }
}
`;
function Posgrado() {
  let { data, errors } = useContentful(query);

  let modalidad = ["Presencial", "a Distancia"];
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin relative">
        <BlockImageCabecera
          src={data.videoTemplate.imageBlock1.url}
          title={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
              <Modal
                title={
                  <>
                    <a className="button">Solicita información</a>
                  </>
                }
                content={
                  <div className="formutpl whitetext">
                    <HubspotForm
                      portalId="3056987"
                      formId="b4c25bdf-48e4-4898-8c40-bfa192032ad2"
                      css=""
                    ></HubspotForm>
                  </div>
                }
              ></Modal>

              <div className="margin">
                <a className="button1" href="#descuentos">
                  <span class="material-icons">chevron_right</span> Accede a
                  descuentos
                </a>
                <a className="button1" href="#becas">
                  <span class="material-icons">chevron_right</span> Postula a
                  una beca
                </a>
              </div>
            </>
          }
        ></BlockImageCabecera>
      </div>
      <div className="relative">
        <Notification
          content={documentToReactComponents(
            data.videoTemplate.contentBlock2.json
          )}
        ></Notification>
        <PosgradoPostulacionFixed></PosgradoPostulacionFixed>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/posgrados">Posgrados</NavLink>
          </>
        }
      ></Breadcumbs>

      <PosgradoOffer
        title={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock4.json)}
          </>
        }
        subtitle="Elige tu posgrado"
      ></PosgradoOffer>
      <PosgradoPagos></PosgradoPagos>
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes de posgrado."
        instagram="https://www.instagram.com/utplposgrados/"
        facebook="https://www.facebook.com/utplposgrados"
      ></ConectUs>
      <BlockBackgroundImage
        src={data.videoTemplate.imageBlock3.url}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock3.json
        )}
      ></BlockBackgroundImage>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Posgrado;
