import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import ConectUs from "../components/ConectUs";
import BlockLeftImage from "../components/BlockLeftImage";

function Alumni() {
  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/alumni"> UTPL Alumni</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="layout8">
        <center>
          <h2 className="tag-sermas">
            Juntos podemos <b>ser más</b>
          </h2>
        </center>
      </div>

      <div className="layout8">
        <h3>
          {" "}
          Bienvenidos a la <b>Comunidad UTPL Alumni</b>
        </h3>
      </div>

      <BlockLeftImage
        src="https://utpl.edu.ec/recursos/img/utplalumni.png"
        alt="utpl"
        content={
          <>
          <h2> Revive los momentos más inolvidables de <b>tu graduación</b></h2>
            <p>
              Explora nuestro álbum de fotos y sumérgete
              en los recuerdos de este día tan especial.
            </p>
            <a target="_blank" rel="noreferrer" href="https://www.flickr.com/photos/utpl/collections/72157722609634165/">Ver álbum</a>
          </>
        }
      ></BlockLeftImage>

      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad UTPL Alumni."
        instagram="https://www.instagram.com/utplALUMNI/"
        facebook="https://www.facebook.com/UTPLAlumni"
        x="https://x.com/UTPLAlumni"
      ></ConectUs>
    </>
  );
}

export default Alumni;
