import image0 from "../img/social/zoom2.png";
import image1 from "../img/social/whatsapp-utpl.png";
import image2 from "../img/social/facebook.png";
import image3 from "../img/social/instagram.png";
import image4 from "../img/social/tiktok.png";
import image5 from "../img/social/twitter.png";
import image6 from "../img/social/flickr.png";
import image7 from "../img/social/youtube.png";
import image8 from "../img/social/in.png";
function Social(){

    return(<>

<a
            target="_blank"
            rel="noreferrer"
            href="https://cedia.zoom.us/j/89419717339"
            id="nav1"
          >
            <img src={image0} alt="utpl" />
          </a>

       <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/593999565400"
            id="nav1"
          >
            <img src={image1} alt="utpl" />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/utploficial"
            id="nav2"
          >
            <img src={image2} alt="utpl" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/utpl/?hl=es-la"
            id="nav3"
          >
            <img src={image3} alt="utpl" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tiktok.com/@utpl.ec"
            id="nav4"
          >
            <img src={image4} alt="utpl" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/utpl?lang=es"
            id="nav5"
          >
            <img src={image5} alt="utpl" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.flickr.com/photos/utpl/"
            id="nav6"
          >
            <img src={image6} alt="utpl" />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/user/utpl/"
            id="nav7"
          >
            <img src={image7} alt="utpl" />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://ec.linkedin.com/school/universidad-tecnica-particular-de-loja-utpl/"
            id="nav8"
          >
            <img src={image8} alt="utpl" />
          </a>
    </>);
}

export default Social;