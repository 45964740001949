import "../css/bannergeneral.css";
import { motion } from "framer-motion";
import line1 from "../img/line1.png";
import line2 from "../img/line2.png";
import { NavLink } from "react-router-dom";

function BannerGeneral() {
  return (
    <>
      <div className="banner-general padding">
        <div className="layout8 lema">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                delay: 0,
                stiffness: 100,
              },
            }}
          >
            <p className="h3">¡Únete a la UTPL y</p>
          </motion.div>

          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                delay: 0.5,
                stiffness: 200,
              },
            }}
          >
            <p className="h2">decide ser más</p>
          </motion.div>

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                delay: 1,
                stiffness: 100,
              },
            }}
          >
            <p className="h4">
              en la universidad <b> más innovadora del Ecuador!</b>
            </p>
          </motion.div>
        </div>

        <div className="banner-sections layout8">
          <div>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
            >
              <h2>Ya te decidiste</h2>
            </motion.div>

            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              <NavLink to="/oferta">
                Oferta académica
                <span className="material-icons">arrow_right_alt</span>
              </NavLink>
            </motion.div>
          </div>
          <div>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
            >
              <h2>Más información</h2>
            </motion.div>

            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              <a href="https://wa.me/593999565400">
                Escríbenos
                <span className="material-icons">arrow_right_alt</span>
              </a>
            </motion.div>
          </div>
          <div>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
            >
              <h2>Más motivación</h2>
            </motion.div>

            <motion.div
              initial={{ y: 100, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              <a href="https://www.youtube.com/watch?v=FLuQ5B9Z7Uc&list=PLKiIhBZ1QsqrEPPUgdD325VQUa8_NgZ_g&pp=iAQB">
                Historias de éxito
                <span className="material-icons">arrow_right_alt</span>
              </a>
            </motion.div>
          </div>
        </div>

        <div className="bg-line1">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                delay: 0.1,
                duration: 5,
              },
            }}
          >
            <img className="line1" src={line1} alt="utpl"></img>
          </motion.div>
        </div>

        <div className="bg-line3">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                delay: 0.2,
                duration: 5,
              },
            }}
          >
            <img className="line2" src={line2} alt="utpl"></img>
          </motion.div>
        </div>
        <div className="bg-line4">
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 5,
              },
            }}
          >
            <img className="line1" src={line2} alt="utpl"></img>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default BannerGeneral;
