import useContentful from "../../hooks/use-contenful";
import Flipcard from "../../components/Flipcard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Accordion from "../../components/Accordion";

const query = `
query 
{
    videoTemplate(id: "12QHIKp1AVTqmb7OauQz53") {
      contentBlock1{json}
      imageBlock2{url, title}
      contentBlock2{json}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}

      
     
    }
  
}
`;

function Asociaciones() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="gobernanza-autoridades padding">
        <div className="layout8">
          {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
          <div className="flex">
            <Flipcard
              src={data.videoTemplate.imageBlock2.url}
              alt={data.videoTemplate.imageBlock2.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock2.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock3.url}
              alt={data.videoTemplate.imageBlock3.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock3.json
              )}
            ></Flipcard>

            <div>
              <Flipcard
                src={data.videoTemplate.imageBlock4.url}
                alt={data.videoTemplate.imageBlock4.title}
                content={
                  <>
                    {documentToReactComponents(
                      data.videoTemplate.contentBlock4.json
                    )}
                  </>
                }
              ></Flipcard>{" "}
              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock5.json
                )}
              ></Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Asociaciones;
