import { useState } from "react";
import "../css/accordion.css";


function Accordion(props) {
  const [state, setState] = useState(false);
  return (
    <div className="wrapper-content">
      <div className={"accordion " + (state?"active":"")}  >
        <div className="layout9">

        {state===false && (
          <div onClick={(e) => setState(true)}>
            {props.title}
            <span class="material-icons">add</span>
          </div>)}

          {state && (
            <div onClick={(e) => setState(false)}>
              {props.title}
              <span class="material-icons">remove</span>
            </div>
          )}
        </div>
      </div>

      {state && (
        <div className="accordion-content">
          <div className="layout9">{props.content}</div>
        </div>
      )}
    </div>
  );
}

export default Accordion;
