import { useQuery } from "@apollo/client";
import Breadcumbs from "../../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import Cabecera from "../../components/Cabecera";
import ViewParagraph from "../../components/ViewParagraph";
import Quote from "../../components/Quote";
import Accordion from "../../components/Accordion";
import PosgradoPagos from "../../components/PosgradoPagos";
import Docentes from "../../components/Docentes";
import BlockLeftImage from "../../components/BlockLeftImage";

import {
  ViewPhoto,
  IsCampoAmplioConocimiento,
  IsModalidad,
  IsTipo,
  IsLink,
  QueryPosgrados,
  Hubspotutpl,
  ViewBrochure,
} from "./Posgradoshooks";

const query = QueryPosgrados(3);

function MaestriaDerechoConstitucional() {
  const { loading, error, data } = useQuery(query);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      <div className="">
        <Cabecera
          src={ViewPhoto(
            data.posgradosOffer.data.attributes.Portada.data.attributes.url
          )}
          tag={IsCampoAmplioConocimiento(
            data.posgradosOffer.data.attributes.Campo
          )}
          modalidad={IsModalidad(data.posgradosOffer.data.attributes.Modalidad)}
          tipo={data.posgradosOffer.data.attributes.Tipomaestria}
          duracion={data.posgradosOffer.data.attributes.Duracion}
          costo={data.posgradosOffer.data.attributes.Precio}
          title={
            <>
              <h2>
                {IsTipo(data.posgradosOffer.data.attributes.Tipo)} en{" "}
                <b>{data.posgradosOffer.data.attributes.Nombre}</b>
              </h2>
              <p>{data.posgradosOffer.data.attributes.Lema}</p>

              {IsLink(data.posgradosOffer.data.attributes.EnlacePostulacion)}
            </>
          }
        ></Cabecera>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/posgrados">Posgrados</NavLink>
            <span class="material-icons">chevron_right</span>
            <NavLink to="/maestria-derecho-constitucional">
              {IsTipo(data.posgradosOffer.data.attributes.Tipo)} en{" "}
              {data.posgradosOffer.data.attributes.Nombre}
            </NavLink>
          </>
        }
      ></Breadcumbs>

      <div className="introduction-maestria layout8 ">
        <div>
          <p>
            <b>Resolución de aprobación:</b>{" "}
            {data.posgradosOffer.data.attributes.Resolucion}
          </p>

          <ViewParagraph
            content={data.posgradosOffer.data.attributes.Descripcion1}
          ></ViewParagraph>
          <Accordion
            title="Requisitos de postulación"
            content={
              <ViewParagraph
                content={data.posgradosOffer.data.attributes.Postulacion}
              ></ViewParagraph>
            }
          ></Accordion>
          <Quote
            content={data.posgradosOffer.data.attributes.Atributo1}
          ></Quote>
          <ViewParagraph
            content={data.posgradosOffer.data.attributes.Descripcion2}
          ></ViewParagraph>
          <Quote
            content={data.posgradosOffer.data.attributes.Atributo2}
          ></Quote>
          <ViewParagraph
            content={data.posgradosOffer.data.attributes.Descripcion3}
          ></ViewParagraph>
        </div>

        <div>
          <div className="formutpl">
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                },
              }}
            >
              {Hubspotutpl(data.posgradosOffer.data.attributes.HubspotID)}
            </motion.div>
          </div>
        </div>
      </div>

      <div className="layout8 padding">
        <center>
          <h2>
            Plan de <b>estudios</b>
          </h2>
        </center>

        <Accordion
          title="Malla académica"
          content={
            <>
              <ViewParagraph
                content={data.posgradosOffer.data.attributes.Malla}
              ></ViewParagraph>
            </>
          }
        ></Accordion>

        <Accordion
          title="Brochure"
          content={
            <>
              {ViewBrochure(data.posgradosOffer.data.attributes.Brochure.data)}
            </>
          }
        ></Accordion>
      </div>
      {/**    <PosgradoPagos></PosgradoPagos>*/}

      <BlockLeftImage
        src={ViewPhoto(
          data.posgradosOffer.data.attributes.Accesosysalidasprofesionales.data
            .attributes.url
        )}
        content={
          <>
            {" "}
            <center>
              <h2>
                Accesos y <b>salidas profesionales</b>{" "}
              </h2>
            </center>
            <Accordion
              title="Perfil de ingreso"
              content={
                <>
                  <ViewParagraph
                    content={data.posgradosOffer.data.attributes.Perfilingreso}
                  ></ViewParagraph>
                </>
              }
            ></Accordion>
            <Accordion
              title="Perfil profesional"
              content={
                <>
                  <ViewParagraph
                    content={
                      data.posgradosOffer.data.attributes.Perfilprofesional
                    }
                  ></ViewParagraph>
                </>
              }
            ></Accordion>
            <Accordion
              title="Campo ocupacional"
              content={
                <>
                  <ViewParagraph
                    content={
                      data.posgradosOffer.data.attributes.Campoocupacional
                    }
                  ></ViewParagraph>
                </>
              }
            ></Accordion>
          </>
        }
      ></BlockLeftImage>

      <div className="bluebg padding diferencia-maestria">
        <div className="layout8">
          <center>
            {" "}
            <h2>
              ¿Qué nos<b> diferencia?</b>
            </h2>
          </center>

          <div>
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                },
              }}
            >
              <ViewParagraph
                content={data.posgradosOffer.data.attributes.Quenosdiferencia}
              ></ViewParagraph>
            </motion.div>
          </div>
        </div>
      </div>

      <Docentes
        content={data.posgradosOffer.data.attributes.docentes}
      ></Docentes>
    </>
  );
}

export default MaestriaDerechoConstitucional;
