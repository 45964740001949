import { gql } from "@apollo/client";
import HubspotForm from "react-hubspot-form";

export function ViewPhoto(img) {
  if (img) {
    return (process.env.REACT_APP_BACKEND_URL + img).toString();
  } else {
    return "";
  }
}

export function ViewBrochure(url) {
  if (url) {
    return (
      <a
        className="button button-posgrados"
        href={(
          process.env.REACT_APP_BACKEND_URL + url.attributes.url
        ).toString()}
      >
        Descargar brochure
      </a>
    );
  } else {
    return "";
  }
}

export function IsCampoAmplioConocimiento(data) {
  if (data === "Ciencias_Sociales_Periodismo_Informacion_y_Derecho")
    return "Ciencias Sociales, Periodismo, Información y Derecho";

  if (data === "Ciencias_Naturales_Matematicas_y_Estadistica")
    return "Ciencias Naturales, Matemáticas y Estadística";
  if (data === "Educacion") return "Educación";
  else return data;
}

export function IsModalidad(data) {
  if (data === "a_distancia") return "A distancia";
  if (data === "en_linea") return "En línea";
  if (data === "presencial") return "Presencial";
}

export function IsTipo(data) {
  if (data === "Maestria") return "Maestría";
  else return data;
}

export function IsResolucion(data) {
  if (data)
    return (
      <>
        <p>{data}</p>
      </>
    );
  else return "";
}

export function IsLink(data) {
  if (data)
    return (
      <>
        <a href={data}>Postula ahora</a>
      </>
    );
  else return "";
}

export function QueryPosgrados(id) {
  const query = gql`
    query {
      posgradosOffer(id: "${id}") {
        data {
          id
          attributes {
            Nombre
            Lema
            Atributo1
            Atributo2
            Descripcion1
            Descripcion2
            Descripcion3
             Tipomaestria
            EnlacePostulacion
            HubspotID
            Resolucion1
             Resolucion2
            Portada {
              data {
                attributes {
                  name
                  url
                }
              }
            }
            Campo
            Modalidad
            Duracion
            Precio
            Tipo
            Postulacion
            Malla
          Brochure{data{attributes{url}}}
            Accesosysalidasprofesionales {
              data {
                attributes {
                  url
                }
              }
            }
            Perfilingreso
            Perfilprofesional
            Campoocupacional
            Quenosdiferencia
            docentes {
              data {
                attributes {
                  Nombre
                  Imagen {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  Enlace
                  Descripcion
                }
              }
            }
          }
        }
      }
    }
  `;
  return query;
}

export function Hubspotutpl(id) {
  if (id) {
    return <HubspotForm portalId="3056987" formId={id} css="" />;
  } else {
    return id;
  }
}
