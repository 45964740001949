
import Menu from "./Menu";
import { NavLink } from "react-router-dom";

import "../css/header.css";

import image1 from "../img/utpl.png";

function Header() {
  return (
    <>
      <div className="wrapper-header">
        <div className="layout9">
          <div className="logo ">
            <div>
              <Menu></Menu>
            </div>

            <div>
              <NavLink to="/">
                <img src={image1} alt="utpl" className="logo"></img>
              </NavLink>
            </div>
          </div>

          <div className="menu-items">
            <NavLink to="/" className="header-opmobile">
              <span class="material-icons">home</span>
            </NavLink>
            <NavLink to="/oferta" className="header-opmobile">
              Oferta académica
            </NavLink>

            <NavLink to="/matriculas">Matricúlate ahora</NavLink>

            <a className="intranet" href="https://soy.utpl.edu.ec">
              Soy UTPL <span class="material-icons">chevron_right</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
