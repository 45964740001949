import Flipcard from "./Flipcard";
import ViewParagraph from "./ViewParagraph";
import "../css/docentes.css";

function Docentes(props) {
  var docentes = "";

  if (props.content.data && props.content.data.length) {
    docentes = props.content.data.map((item) =>
      HandleInfor(
        item.attributes.Nombre,
        item.attributes.Enlace,
        item.attributes.Descripcion,
        item.attributes.Imagen.data.attributes.url
      )
    );
  } else {
    docentes = 0;
  }

  function IsLink(enlace) {
    if (enlace) {
      return (
        <>
          <a className="button" target="_blank" href={enlace}>
            Ver perfil
          </a>
        </>
      );
    }
  }

  function HandleInfor(nombre, enlace, Descripcion, imagen) {
    if (nombre) {
      return (
        <Flipcard
          content={
            <>
              <p>
                <b> {nombre}</b>
              </p>
              <ViewParagraph content={Descripcion}></ViewParagraph>
              {IsLink(enlace)}
            </>
          }
          src={(process.env.REACT_APP_BACKEND_URL + imagen).toString()}
        ></Flipcard>
      );
    } else {
      return 0;
    }
  }

  if (docentes !== 0) {
    return (
      <>
        <div className="padding">
         <center><h2>Docentes</h2></center> 
       
          <div className="wrapper-docentes ">{docentes} </div>
        </div>
      </>
    );
  }
}

export default Docentes;
