import { NavLink } from "react-router-dom";

function Breadcumbs(props) {
  return (
    <div className="wrapper-breadcumbs">
      <div className="layout8">
        <NavLink to="/">Inicio</NavLink>
        <span class="material-icons">chevron_right</span>
        {props.content}
      </div>
    </div>
  );
}

export default Breadcumbs;
