import useContentful from "../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BannerGeneral from "../components/BannerGeneral";
import BlockRightImage from "../components/BlockRightImage";
import ModalFixed from "../components/ModalFixed";
import BlockHerramientas from "../components/BlockHerramientas";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";

const query = `
query 
{
    videoTemplate(id: "7tomS9jVwDNiklbCbGr014") {
        title
        video{url}
        imageBlock1{url, title}
        contentBlock1{json}
        imageBlock2{url, title}
        contentBlock2{json}
        imageBlock3{url, title}
        contentBlock3{json}
        imageBlock4{url, title}
        contentBlock4{json}
        imageBlock5{url, title}
        contentBlock5{json}
        imageBlock6{url, title}
        contentBlock6{json}
     
    }
}
`;

function Herramientas() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin ">
        <Breadcumbs
          content={
            <>
              <NavLink to="/distancia">Modalidad a distancia</NavLink>
              <span class="material-icons">chevron_right</span>
              <NavLink to="/herramientas">Herramientas tecnológicas</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <div className="relative ">
        <div className="layout8  padding2 ">
          <h2 className="width500">{data.videoTemplate.title}</h2>
        </div>

        <ModalFixed
          color="#cd253e"
          title="Herramientas tecnológicas para estudiar a distancia en la UTPL 💙"
          content={
            <>
              <iframe
                width="100%"
                height="500"
                allow="autoplay"
                src={data.videoTemplate.video.url}
              ></iframe>
            </>
          }
        ></ModalFixed>
      </div>
      <div className="graybg">
      <BlockRightImage
        src={data.videoTemplate.imageBlock1.url}
        alt={data.videoTemplate.imageBlock1.alt}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock1.json
        )}
      ></BlockRightImage></div>
      <div className="layout8 margin">
        <h2 className="width500">
          Interactúa en vivo con nuestras <b>iniciativas más destacadas</b>{" "}
        </h2>
      </div>
      <BlockHerramientas
        bgimage="#FF5733"
        src={data.videoTemplate.imageBlock2.url}
        alt={data.videoTemplate.imageBlock2.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock2.json
        )}
      ></BlockHerramientas>{" "}
      <div className="graybg">
        <BlockHerramientas
          bgimage="#27D890"
          src={data.videoTemplate.imageBlock3.url}
          alt={data.videoTemplate.imageBlock3.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock3.json
          )}
        ></BlockHerramientas>{" "}
      </div>
      <BlockHerramientas
        bgimage="#2E7DC3"
        src={data.videoTemplate.imageBlock4.url}
        alt={data.videoTemplate.imageBlock4.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock4.json
        )}
      ></BlockHerramientas>
      <div className="graybg">
        <BlockHerramientas
          bgimage="#6fb2e0"
          src={data.videoTemplate.imageBlock5.url}
          alt={data.videoTemplate.imageBlock5.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock5.json
          )}
        ></BlockHerramientas>
      </div>
      <BlockHerramientas
        bgimage="#762EC3"
        src={data.videoTemplate.imageBlock6.url}
        alt={data.videoTemplate.imageBlock6.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock6.json
        )}
      ></BlockHerramientas>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Herramientas;
