import "../css/gallery.css";

function Gallery(props) {

  const handleRight = (e) => {
    e.preventDefault();
    const element = document.getElementById("wrappergallery");
    element.scrollLeft += 400;
  };

  const handleLeft = (e) => {
    e.preventDefault();
    const element = document.getElementById("wrappergallery");
    element.scrollLeft -= 400;
  };

  const mystyle = { width: props.width + "px" };

  const mystyle1 = { minWidth: props.width + "px" };
  return (
    <>
      <div className="wrapper-gallery">
        <div id="wrappergallery" style={mystyle}>
          {props.gallery.map((novedad) => (
            <>
              <div
                className="item-gallery"
                key={novedad.title}
                style={mystyle1}
              >
                <img src={novedad.url} alt={novedad.title}></img>

                {/*
                <div>
                  <span class="material-icons">radio_button_unchecked</span>
                </div>*/}
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="gallery-indicators">
        <span className="material-icons" onClick={handleLeft}>
          arrow_back
        </span>
        <span className="material-icons" onClick={handleRight}>
          arrow_forward
        </span>
      </div>
    </>
  );
}

export default Gallery;
