import BannerGeneral from "../components/BannerGeneral";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BlockImageCabecera from "../components/BlockImageCabecera";
import useContentful from "../hooks/use-contenful";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";

import BlockRightImage from "../components/BlockRightImage";
import ConectUs from "../components/ConectUs";

import FormacionPermanenteOffer from "../components/FormacionPermanenteOffer";

const query = `
query 
{
    videoTemplate(id: "5e8Onqr0WtTIMSs0VBu01d") {
      video{url}
      imageBlock1{url}
      contentBlock1{json}
      imageBlock2{url}
      contentBlock2{json}
      contentBlock3{json}
      imageBlock4{url}
      contentBlock4{json}
      contentBlock11{json}
      
      galleryCollection{
        items{url title}
         }
    }
}
`;
function FormacionPermanente() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin relative">
        <BlockImageCabecera
          src={data.videoTemplate.imageBlock1.url}
          title={documentToReactComponents(
            data.videoTemplate.contentBlock1.json
          )}
        ></BlockImageCabecera>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/formacionpermanente">Formación Permanente</NavLink>
          </>
        }
      ></Breadcumbs>

      <FormacionPermanenteOffer></FormacionPermanenteOffer>
      <div className="padding layout8">
        <center>
          {documentToReactComponents(data.videoTemplate.contentBlock11.json)}
        </center>
        <div className="gallery-formacionpermanente padding2">
          {data.videoTemplate.galleryCollection.items.map((novedad) => (
            <>
              <div className="item" key={novedad.title}>
                <img src={novedad.url} alt={novedad.title}></img>
              </div>
            </>
          ))}
        </div>
      </div>

      <BlockBackgroundImage
        src={data.videoTemplate.imageBlock2.url}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock2.json
        )}
      ></BlockBackgroundImage>

      <BlockRightImage
        src={data.videoTemplate.imageBlock4.url}
        alt={data.videoTemplate.imageBlock4.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock4.json
        )}
      ></BlockRightImage>

      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes de formación permanente."
        instagram="https://www.instagram.com/formacionpermanenteutpl/"
        facebook="https://www.facebook.com/formacionpermanenteutpl"
        x="https://twitter.com/PermanenteUtpl"
        youtube="https://www.youtube.com/channel/UCzP4vqpQ-u_FiKNi8Dv2vVw"
        tiktok="https://tiktok.com/@forpermanenteutpl"
        flickr="https://www.flickr.com/photos/194885809@N04/"
        linkedin="https://www.linkedin.com/company/educaci%C3%B3n-continua-utpl/posts/?feedView=all"
      ></ConectUs>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default FormacionPermanente;
