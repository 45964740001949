import Block3ColumnsText from "../../components/Block3ColumnsText";

import BlockLeftImage from "../../components/BlockLeftImage";
import BlockRightImage from "../../components/BlockRightImage";
import Flipcard from "../../components/Flipcard";

function Gem() {
  return (
    <>
      <div className="margin">
        <div className="layout8 padding">
          <h2>
            {" "}
            Bienvenido al{" "}
            <b>Informe Global Entrepreneurship Monitor (GEM) 2023</b>
          </h2>

          <p>
            En marzo de 2023, la{" "}
            <b>
              Universidad Técnica Particular de Loja (UTPL) y la Escuela
              Superior Politécnica del Litoral (ESPOL)
            </b>{" "}
            firmaron un convenio que marcó el inicio del{" "}
            <b>Global Entrepreneurship Monitor GEM 2023</b>, considerado el
            estudio más grande a nivel mundial para medir el nivel de
            emprendimiento.
          </p>
          <p>
            Tras más de una de investigación y recolección de información
            liderada por nuestra <b>Escuela de Negocios con Propósito EDES</b>,
            la
            <b> Facultad de Ciencias Económicas y Empresariales</b> y la{" "}
            <b>Cátedra de Innovación y Emprendimiento</b>, en colaboración
            estratégica con la
            <b> Escuela de Negocios ESPAE de la ESPOL</b>, nos enorgullece
            presentar los resultados del informe GEM 2023 a nivel global, así
            como los resultados específicos para nuestro país.
          </p>
          <p>
            La elaboración de este prestigioso informe es fruto de nuestro
            compromiso con la investigación, innovación y emprendimiento, a fin
            de fortalecer el desarrollo económico y social, y transferir
            conocimiento para aportar a la toma de decisiones y formulación de
            política públicas.
          </p>
        </div>
      </div>
      <BlockLeftImage
        src="https://www.gemconsortium.org/images/latest-global-reports/2024/reports-1707825686.jpg"
        content={
          <>
            <h2>
              Informe <b>Global GEM 2023</b>
            </h2>
            <p>
              Descubre las tendencias globales en emprendimiento que están
              moldeando el futuro del panorama empresarial internacional. Este
              informe ofrece conocimientos esenciales para la toma de decisiones
              estratégicas y la planificación de acciones que fomentan el
              crecimiento empresarial. La presentación de este informe tuvo
              lugar en Marruecos en febrero de 2023.
            </p>

            <a
              target="_blank"
              href="https://noticias.utpl.edu.ec/instituciones-de-educacion-superior-de-latinoamerica-presentaran-el-global-entrepreneurship-monitor-en-marruecos-0"
            >
              Más información
            </a>

            <a
              target="_blank"
              href="https://www.gemconsortium.org/file/open?fileId=51377"
            >
              Descarga aquí el informe
            </a>
          </>
        }
      ></BlockLeftImage>

      <img src="https://noticias.utpl.edu.ec/sites/default/files/styles/noticias_slide/public/multimedia/edes_utpl.png?itok=YmqwmkyC"></img>

      <div className="layout8 margin">
        <center>
          <h2>
            Informe <b>Nacional GEM 2023</b>
          </h2>
        </center>

        <p>
          Proporciona una visión detallada de las tendencias y dinámicas del
          ecosistema emprendedor en Ecuador, ofreciendo datos clave y análisis
          profundos que son esenciales para emprendedores, académicos,
          formuladores de políticas y todos aquellos interesados en impulsar el
          crecimiento y la sostenibilidad de nuevos negocios en nuestro país.
        </p>
        <center>
          <a
            className="button"
            href="https://eventos.utpl.edu.ec/sites/default/files/files/informe%20GEM22(1).pdf"
          >
            Descarga aquí el informe
          </a>

          <br></br>
          <h3>
            Descubre los <b>principales hallazgos del GEM Ecuador 2023</b>
          </h3>
        </center>
      </div>
      <div className="padding2">
        <Block3ColumnsText
          col1={
            <>
              <ul>
                <li>
                  <p>
                    Ecuador lidera la región en actividad emprendedora con un{" "}
                    <b>TEA del 32.7%</b>
                  </p>
                </li>

                <li>
                  <p>
                    El 53% de emprendedores tiene <b>menos de 35 años</b>
                  </p>
                </li>
                <li>
                  <p>
                    El 52% tiene <b> educación secundaria completa</b>
                  </p>
                </li>
              </ul>
            </>
          }
          col2={
            <>
              <ul>
                <li>
                  <p>
                    El 64% de emprendedores <b>reside en el área urbana</b>
                  </p>
                </li>

                <li>
                  <p>El 59% se dedica exclusivamente a su negocio</p>
                </li>
                <li>
                  <p>
                    La percepción del entorno emprendedor es desafiante, con una{" "}
                    <b>valoración media de 3.9 sobre 10</b>
                  </p>
                </li>
              </ul>
            </>
          }
          col3={
            <>
              <ul>
                <li>
                  <p>
                    Existe un{" "}
                    <b>marcado interés por la sostenibilidad y la innovación</b>{" "}
                    entre los emprendedores ecuatorianos.
                  </p>
                </li>

                <li>
                  <p>
                    {" "}
                    El{" "}
                    <b>
                      apoyo financiero y la transferencia de I+D son
                      insuficientes
                    </b>
                    , lo que representa un obstáculo significativo
                  </p>
                </li>
                <li>
                  <p>
                    Las mujeres enfrentan mayores desafíos en el ecosistema
                    emprendedor, especialmente en el{" "}
                    <b>acceso a recursos y educación</b>
                  </p>
                </li>
                <li>
                  <p>
                    Los emprendedores más jóvenes están más inclinados a
                    considerar los{" "}
                    <b>impactos sociales y ambientales en sus negocios</b>
                  </p>
                </li>
              </ul>
            </>
          }
        ></Block3ColumnsText>

        
<div className="padding2">
          <center>
            {" "}
            <h2>Equipo investigador</h2>
          </center>

          <div className="flex-center">
            <Flipcard
              src="https://eventos.utpl.edu.ec/sites/default/files/images/pon3.png"
              content={
                <>
                  <p>María Paula Espinosa Vélez</p>
                  <p>Investigadora – Directora Unidad Académica EDES, UTPL</p>
                  <a href="https://investigacion.utpl.edu.ec/mpespinoza ">
                    Ver perfil
                  </a>
                </>
              }
            ></Flipcard>
             <Flipcard
              src="https://eventos.utpl.edu.ec/sites/default/files/images/pon5.png"
              content={
                <>
                  <p>Paul Sarango Lalangui</p>
                  <p>Investigador - Facultad de Ciencias Económicas y Empresariales, UTPL </p>
                  <a href="https://investigacion.utpl.edu.ec/posarango ">
                    Ver perfil
                  </a>
                </>
              }
            ></Flipcard>
             <Flipcard
              src="https://eventos.utpl.edu.ec/sites/default/files/images/pon2.png"
              content={
                <>
                  <p>María Dolores Mahuad Burneo</p>
                  <p>Investigadora - Cátedra de Innovación y Emprendimiento, UTPL</p>
                  <a href="https://investigacion.utpl.edu.ec/mdmahauad">
                    Ver perfil
                  </a>
                </>
              }
            ></Flipcard>

<Flipcard
              src="https://eventos.utpl.edu.ec/sites/default/files/images/pon4.png"
              content={
                <>
                  <p>María Virginia Lasio Morello</p>
                  <p>Investigadora - ESPOL </p>
                  <a href="https://www.espae.edu.ec/virginia-lasio/">
                    Ver perfil
                  </a>
                </>
              }
            ></Flipcard>

             <Flipcard
              src="https://eventos.utpl.edu.ec/sites/default/files/images/pon1.png"
              content={
                <>
                  <p>Adriana Andrea Amaya Rivas</p>
                  <p>Investigadora - ESPOL </p>
                  <a href="https://www.espae.edu.ec/adriana-amaya/ ">
                    Ver perfil
                  </a>
                </>
              }
            ></Flipcard>
          
          </div>
        </div>

        <div className="layout8 padding">
          <center>
            {" "}
            <h2>
              <b>Promoviendo la innovación y el emprendimiento</b> como pilares
              estratégicos, fortalecemos el ecosistema emprendedor del país
            </h2>
          </center>

          <div className="flex-center icon200m">
            {" "}
            <img src="https://utpl.edu.ec/recursos/img/utpl2.png"></img>
            <img src="https://eventos.utpl.edu.ec/sites/default/files/images/espol.png"></img>
          </div>
        </div>


        <div className="layout8  ">
          <center>
            {" "}
            <h2>Con el apoyo de</h2>
          </center>

          <div className="flex-center icon200m">
            <div>
              {" "}
              <img src="https://edes.utpl.edu.ec/wp-content/uploads/2024/03/EDES-Awarenes-Final_Mesa-de-trabajo-1-2048x681.png"></img>
            </div>
            <div>
              <img src="https://www.espae.edu.ec/wp-content/uploads/2021/02/ESPAE.png"></img>
            </div>
            <div>
              {" "}
              <img src="https://eventos.utpl.edu.ec/sites/default/files/files/catedrainnovacion.png"></img>
            </div>
            <div>
              {" "}
              <img src="https://eventos.utpl.edu.ec/sites/default/files/files/facultadciencias.png"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gem;
