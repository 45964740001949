import "../css/quote.css";
import { motion } from "framer-motion";

function Quote(props) {
  if (props.content) {
    return (
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        className="wrapper-quote"
        whileInView={{
          x: 0,
          opacity: 1,
          transition: {
            delay: 0.4,
          },
        }}
      >
        <p>{props.content}</p>
      </motion.div>
    );
  }else return"";
}

export default Quote;
