import { useState, useEffect } from "react";

function useContentful(query) {
    let [data, setData] = useState(null);
    let [errors, setErrors] = useState(null);

    useEffect(() => {
        window
            .fetch(
                'https://graphql.contentful.com/content/v1/spaces/112w21q1rvqr/?access_token=XjQO9qPKlXQaEnTIc5WwrK-JfmQuovi-36QRzzFWa6Q',
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ query }),
                }
            )
            .then((response) => response.json())
            .then(({data, errors})  => {
            if(errors) setErrors(errors)
            if(data) setData(data)

            })
            .catch ((error) => setErrors ([error]));
          
    }, [query]);

    return { data, errors };
}
export default useContentful;