import useContentful from "../hooks/use-contenful";
import { useState } from "react";
import "../css/academicoffer.css";

function AcademicOffer(props) {
  const [state, setState] = useState(0);

  let query = "";
  let key = "0";
  let programa = "0";
  let modalidad = "0";

  if (props.keyword) {
    key = props.keyword;
  } else {
    key = 0;
  }
  if (props.programa) {
    programa = props.programa.value;
  } else {
    programa = 0;
  }
  if (props.modalidad) {
    modalidad = props.modalidad.value;
  } else {
    modalidad = 0;
  }

  if (programa !== 0 && modalidad !== 0 && key !== 0) {
    query = `query 
      {
       academicProgramsCollection(where:{
        tipoDePrograma:"${programa}"
        modalidad_contains_all:"${modalidad}"
         keywords_contains_all: "${key}"
       }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
         tipoDePrograma
         tipoDePosgrado
         tipoPosgrado
         campoconocimientoPosgrado
         facultad
         modalidad
         duracion
         inicio
         enlaceAPrograma
         keywords
         
       } 
       }
       }  
      `;
  } else if (programa !== 0 && modalidad !== 0 && key === 0) {
    query = `query 
    {
     academicProgramsCollection(where:{
      tipoDePrograma:"${programa}"
      modalidad_contains_all:"${modalidad}"
     }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
       tipoDePrograma
       tipoDePosgrado
       tipoPosgrado
       campoconocimientoPosgrado
       facultad
       modalidad
       duracion
       inicio
       enlaceAPrograma
       keywords
       
     } 
     }
     }  
    `;
  } else if (programa !== 0 && modalidad === 0 && key === 0) {
    query = `query 
      {
       academicProgramsCollection(where:{
        tipoDePrograma:"${programa}"
         }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
         tipoDePrograma
         tipoDePosgrado
         tipoPosgrado
         campoconocimientoPosgrado
         facultad
         modalidad
         duracion
         inicio
         enlaceAPrograma
         keywords
         
       } 
       }
       }  
      `;
  } else if (programa === 0 && modalidad !== 0 && key === 0) {
    query = `query 
      {
       academicProgramsCollection(where:{
        modalidad_contains_all:"${modalidad}"
         }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
         tipoDePrograma
         tipoDePosgrado
         tipoPosgrado
         campoconocimientoPosgrado
         facultad
         modalidad
         duracion
         inicio
         enlaceAPrograma
         keywords
         
       } 
       }
       }  
      `;
  } else if (programa === 0 && modalidad !== 0 && key === 0) {
    query = `query 
    {
     academicProgramsCollection(where:{
      tipoDePrograma:"${programa}"
      keywords_contains_all: "${key}"
       }order:[ofertaActual_DESC,nombre_ASC ]){items{
       sys{id}
       nombre
       tipoDePrograma
       tipoDePosgrado
       tipoPosgrado
       campoconocimientoPosgrado
       facultad
       modalidad
       duracion
       inicio
       enlaceAPrograma
       keywords
       
     } 
     }
     }  
    `;
  } else  {
    query = `query 
      {
       academicProgramsCollection(where:{
        keywords_contains_all: "${key}"
         }order:[ofertaActual_DESC,nombre_ASC ]){items{
         sys{id}
         nombre
         tipoDePrograma
         tipoDePosgrado
         tipoPosgrado
         campoconocimientoPosgrado
         facultad
         modalidad
         duracion
         inicio
         enlaceAPrograma
         keywords
         
       } 
       }
       }  
      `;
  }

  function IsModalidad(modalidad) {
    try {
      if (modalidad[1])
        return (
          <p>
            <b>Modalidad: </b>
            {modalidad[0].toLowerCase()}, {modalidad[1].toLowerCase()}
          </p>
        );
      else
        return (
          <p>
            <b>Modalidad: </b>
            {modalidad[0].toLowerCase()}
          </p>
        );
    } catch {
      return modalidad;
    }
  }

  function IsFacultad(facultad, posgrado) {
    try {
      if (posgrado) return "";
      else
        return (
          <p>
            <b>Facultad: </b>
            {facultad}
          </p>
        );
    } catch {
      return modalidad;
    }
  }

  function IsTipoPosgrado(tipo, programa) {
    try {
      if (tipo)
        return (
          <>
            <p>
              <b>Programa:</b> {tipo}
            </p>
          </>
        );
      else
        return (
          <>
            <p>
              <b>Programa:</b> {programa}
            </p>
          </>
        );
    } catch {
      return tipo;
    }
  }

  function IsPosgrado(pos) {
    try {
      if (pos)
        return (
          <>
            <p>
              <b>Tipo:</b> {pos}
            </p>
          </>
        );
      else return pos;
    } catch {
      return pos;
    }
  }

  function IsCampoAmplio(campo) {
    try {
      if (campo)
        return (
          <>
            <p>
              <b>Campo del conocimiento:</b> {campo}
            </p>
          </>
        );
      else return campo;
    } catch {
      return campo;
    }
  }

  function IsInicio(inicio) {
    try {
      if (inicio[1]) {
        return (
          <>
            <b>Inicio de clases:</b>
            <p>{inicio[0]}</p>
            <p>{inicio[1]}</p>
          </>
        );
      } else {
        return (
          <>
            <b>Inicio de clases:</b> {inicio[0]}
          </>
        );
      }
    } catch (error) {
      return "";
    }
  }

  function ViewGrid() {
    setState(0);
  }

  function ViewList() {
    setState(1);
  }

  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="wrapper-view">
        {state === 0 && (
          <div>
            <p onClick={ViewList}>Vista de cuadrícula </p>
            <span class="material-icons">grid_view</span>
          </div>
        )}
        {state === 1 && (
          <div>
            <p onClick={ViewGrid}>Vista de lista </p>
            <span class="material-icons">view_list</span>
          </div>
        )}
      </div>
      <br></br>
      {state === 0 && (
        <div className="wrapper-academicoffer">
          {data?.academicProgramsCollection.items?.map((info) => (
            <>
              <div key={info.sys.id} className={info.tipoDePrograma}>
                <div className="layout8">
                  <h3>{info.nombre}</h3>
                  {IsTipoPosgrado(info.tipoDePosgrado, info.tipoDePrograma)}
                  {IsPosgrado(info.tipoPosgrado)}

                  {IsModalidad(info.modalidad)}

                  {IsFacultad(info.facultad, info.tipoDePosgrado)}

                  <p>
                    <b>Duración:</b> {info.duracion}
                  </p>
                  {IsCampoAmplio(info.campoconocimientoPosgrado)}
                  <p>{IsInicio(info.inicio)}</p>
                  <div className="wrapper-link">
                    <a
                      className="link-offer"
                      href={info.enlaceAPrograma}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ver programa
                      <span className="material-icons">arrow_forward_ios </span>
                    </a>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {state === 1 && (
        <div className="wrapper-academicofferlist layout9">
          {data?.academicProgramsCollection.items?.map((info) => (
            <>
              <div key={info.sys.id}>
                <a href={info.enlaceAPrograma} target="_blank" rel="noreferrer">
                  <span class="material-icons">chevron_right</span>{" "}
                  {info.nombre}
                </a>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
}

export default AcademicOffer;
