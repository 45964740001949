import { Route, Routes } from "react-router-dom";
import MaestriaCooperacion from "./MaestriaCooperacion";
import MaestriaCienciasQuimicas from "./MaestriaCienciasQuimicas";
import MaestriaDerechoConstitucional from "./MaestriaDerechoConstitucional";
import MaestriaDerechoProcesal from "./MaestriaDerechoProcesal";
import MaestriaEducacionMatematica from "./MaestriaEducacionMatematica";
import MaestriaInvestigacionEducacion from "./MaestriaInvestigacionEducacion";
import MaestriaPedagogiaArtes from "./MaestriaPedagogiaArtes";
import MaestriaAlimentos from "./MaestriaAlimentos";
import MaestriaComunicacionEstrategica from "./MaestriaComunicacionEstrategica";
import MaestriaRecursosHidricos from "./MaestriaRecursosHidricos";
import MaestriaIdiomas from "./MaestriaIdiomas";

export default function RoutesPosgrados() {
  return (
    <>
      <Routes>
     

        <Route path="/maestria-cooperacion" element={<MaestriaCooperacion />}></Route>
        <Route path="/maestria-quimica" element={<MaestriaCienciasQuimicas />}></Route>
        <Route path="/maestria-derecho-constitucional" element={<MaestriaDerechoConstitucional />}></Route>
        <Route path="/maestria-derecho-procesal" element={<MaestriaDerechoProcesal />}></Route>
        <Route path="/maestria-educacion" element={<MaestriaEducacionMatematica />}></Route>
        <Route path="/maestria-investigacion-educacion" element={<MaestriaInvestigacionEducacion />}></Route>
        <Route path="/maestria-pedagogia-artes" element={<MaestriaPedagogiaArtes />}></Route>
        <Route path="/maestria-gestion-alimentos" element={<MaestriaAlimentos />}></Route>
        <Route path="/maestria-comunicacion-estrategica" element={<MaestriaComunicacionEstrategica />}></Route>
        <Route path="/maestria-recursos-hidricos" element={<MaestriaRecursosHidricos />}></Route>
        <Route path="/maestria-idiomas" element={<MaestriaIdiomas />}></Route>

      </Routes>
    </>
  );
}
