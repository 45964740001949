import useContentful from "../../hooks/use-contenful";
import Flipcard from "../../components/Flipcard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Accordion from "../../components/Accordion";

const query = `
query 
{
    videoTemplate(id: "5PwD6HsfhnWasv0O0xGyWA") {
      contentBlock1{json}
      imageBlock2{url, title}
      contentBlock2{json}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}
      contentBlock7{json}
      contentBlock8{json}
      imageBlock9{url, title}
      contentBlock9{json}
      contentBlock10{json}
      contentBlock11{json}
      imageBlock12{url, title}
      contentBlock12{json}
      contentBlock13{json}

      
     
    }
  
}
`;

function Autoridadesacademicas() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="gobernanza-autoridades padding">
        <div className="layout8">
          {documentToReactComponents(data.videoTemplate.contentBlock1.json)}

          <div className="wrapper-autoridades-academicas">
            <div>
              {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
              <Flipcard
                src={data.videoTemplate.imageBlock3.url}
                alt={data.videoTemplate.imageBlock3.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock3.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock4.json
                )}
              ></Accordion>
            </div>
            <div>
              {documentToReactComponents(data.videoTemplate.contentBlock5.json)}

              <Flipcard
                src={data.videoTemplate.imageBlock6.url}
                alt={data.videoTemplate.imageBlock6.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock6.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock7.json
                )}
              ></Accordion>
            </div>

            <div>
              {documentToReactComponents(data.videoTemplate.contentBlock8.json)}
              <Flipcard
                src={data.videoTemplate.imageBlock9.url}
                alt={data.videoTemplate.imageBlock9.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock9.json
                )}
              ></Flipcard>
              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock10.json
                )}
              ></Accordion>
            </div>

            <div>
              {documentToReactComponents(
                data.videoTemplate.contentBlock11.json
              )}

              <Flipcard
                src={data.videoTemplate.imageBlock12.url}
                alt={data.videoTemplate.imageBlock12.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock12.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock13.json
                )}
              ></Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Autoridadesacademicas;
