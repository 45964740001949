import "../css/modal.css";
import { useState } from "react";

import { motion } from "framer-motion";

function Modal(props) {
  const [state, setState] = useState(false);

  return (
    <>
      <p className="modal-open" onClick={(e) => setState(true)}>
        {props.title}
      </p>

      {state && (
        <motion.div
          className="wrapper-contentcard"
          initial={{ y: 100, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
            },
          }}
        >
          <div>
            <span
              className="material-icons close-modalcard"
              onClick={(e) => setState(false)}
            >
              close
            </span>
            {props.content}
          </div>
        </motion.div>
      )}
    </>
  );
}

export default Modal;
