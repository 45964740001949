import BannerGeneral from "../components/BannerGeneral";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import ConectUs from "../components/ConectUs";
import ConsejoSuperior from "./gobernanza/ConsejoSuperior";
import Cogobierno from "./gobernanza/Cogobierno";
import ConsejoTutelar from "./gobernanza/ConsejoTutelar";
import Autoridadesejecutivas from "./gobernanza/Autoridadesejecutivas";
import Mad from "./gobernanza/Mad";
import Investigacion from "./gobernanza/Investigacion";
import DireccionesGenerales from "./gobernanza/DireccionesGenerales";
import Autoridadesacademicas from "./gobernanza/Autoridadesacademicas";
import Asociaciones from "./gobernanza/Asociaciones";
import Autoridadesacademicas2 from "./gobernanza/Autoridadesacademicas2";

function NewGobernanza() {
  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/gobernanza">Gobernanza</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <ConsejoSuperior></ConsejoSuperior>
      <Cogobierno></Cogobierno>
      <ConsejoTutelar></ConsejoTutelar>

      <div className="wrapper-gobernanza">
        <Autoridadesejecutivas></Autoridadesejecutivas>
        <Mad></Mad>
        <Investigacion></Investigacion>
        <DireccionesGenerales></DireccionesGenerales>
        <Autoridadesacademicas></Autoridadesacademicas>
        <Autoridadesacademicas2></Autoridadesacademicas2>
        <Asociaciones></Asociaciones>
      </div>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default NewGobernanza;
