import "../css/blockcolumn.css";
import { motion } from "framer-motion";
import line1 from "../img/line1.png";
import line2 from "../img/line2.png";

function Block4ColumnsText(props) {
  return (
    <div className="block-columntext block-column relative">
      <div className="layout8">
        <div className="col4">
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.5,
              },
            }}
          >
            <div className="layout9 content">{props.col1}</div>
          </motion.div>
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 1,
              },
            }}
          >
            <div className="layout9 content">{props.col2}</div>
          </motion.div>

          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 1.5,
              },
            }}
          >
            <div className="layout9 content">{props.col3}</div>
          </motion.div>

          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 2,
              },
            }}
          >
            <div className="layout9 content">{props.col4}</div>
          </motion.div>
        </div>
      </div>
      <div className="bg-line1">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              duration: 2,
            },
          }}
        >
          <img className="line1" src={line1} alt="utpl"></img>
        </motion.div>
      </div>
      <div className="bg-line2">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              duration: 0.5,
              delay:0.1,
            },
          }}
        >
          <img className="line2" src={line2} alt="utpl"></img>
        </motion.div>
      </div>
      <div className="bg-line3">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              duration: 1,
            },
          }}
        >
          <img className="line1" src={line1} alt="utpl"></img>
        </motion.div>
      </div>
      <div className="bg-line4">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              duration: 0.5,
              delay:0.1,
            },
          }}
        >
          <img className="line2" src={line2} alt="utpl"></img>
        </motion.div>
      </div>{" "}
    </div>
  );
}

export default Block4ColumnsText;
