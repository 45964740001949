import Accordion from "../components/Accordion";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import formulario from "../docs/rendicion/2022/formulario.xlsx";
import informeanual from "../docs/rendicion/2022/informeanual.pdf";
import fase0_1_2022 from "../docs/rendicion/2022/fase0_1.pdf";
import fase0_2_2022 from "../docs/rendicion/2022/fase0_2.pdf";
import fase1_1_2022 from "../docs/rendicion/2022/fase1_1.pdf";
import fase1_2_2022 from "../docs/rendicion/2022/fase1_2.pdf";
import fase1_3_2022 from "../docs/rendicion/2022/fase1_3.pdf";
import fase1_4_2022 from "../docs/rendicion/2022/fase1_4.pdf";
import fase2_1_2022 from "../docs/rendicion/2022/fase2_1.pdf";
import fase2_2_2022 from "../docs/rendicion/2022/fase2_2.pdf";
import fase2_3_2022 from "../docs/rendicion/2022/fase2_3.pdf";
import fase2_5_2022 from "../docs/rendicion/2022/fase2_5.pdf";
import fase3_1_2022 from "../docs/rendicion/2022/fase3_1.pdf";
import fase3_2_2022 from "../docs/rendicion/2022/fase3_2.pdf";

import formulario_2021 from "../docs/rendicion/2021/formulario.xlsx";
import informeanual_2021 from "../docs/rendicion/2021/informe.pdf";
import fase0_1_2021 from "../docs/rendicion/2021/fase0_1.pdf";
import fase0_2_2021 from "../docs/rendicion/2021/fase0_2.pdf";
import fase1_1_2021 from "../docs/rendicion/2021/fase1_1.pdf";
import fase1_2_2021 from "../docs/rendicion/2021/fase1_2.pdf";
import fase1_3_2021 from "../docs/rendicion/2021/fase1_3.pdf";
import fase1_4_2021 from "../docs/rendicion/2021/fase1_4.pdf";
import fase2_1_2021 from "../docs/rendicion/2021/fase2_1.pdf";
import fase2_2_2021 from "../docs/rendicion/2021/fase2_2.pdf";
import fase2_3_2021 from "../docs/rendicion/2021/fase2_3.pdf";
import fase2_5_2021 from "../docs/rendicion/2021/fase2_5.pdf";
import fase3_1_2021 from "../docs/rendicion/2021/fase3_1.pdf";
import fase3_2_2021 from "../docs/rendicion/2021/fase3_2.pdf";
import useContentful from "../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const query = `
query 
{
    videoTemplate(id: "7nkXFo1MUjpp4KrAvMneT0") {
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      contentBlock5{json}
    }
  
}
`;
function Rendicion() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;
  return (
    <>

    <div className="margin">
    <Breadcumbs
        content={
          <>
            <NavLink to="/rendicioncuentas">Rendición de cuentas</NavLink>
            
          </>
        }
      ></Breadcumbs>
      
    </div>
      <div className="padding  graybg">
        <div className="layout8">
          {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
          <Accordion
            title="Fase 0"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock2.json
            )}
          ></Accordion>
             <Accordion
            title="Fase 1"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock3.json
            )}
          ></Accordion>
             <Accordion
            title="Fase 2"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock4.json
            )}
          ></Accordion>
             <Accordion
            title="Fase 3"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock5.json
            )}
          ></Accordion>
        </div>
      </div>
      <div className="layout8 padding">
        <h2>Periodo 2022</h2>
        <div >
          <ul className="padding2">
            <li>
              <a href={informeanual}>Informe anual</a>
            </li>
            <li>
              <a href={formulario}>
                Formulario del Consejo de Participación Ciudadana y Control
                Social
              </a>
            </li>
          </ul>
          <Accordion
            title="Fase 0"
            content={
              <>
                {" "}
                <p>
                  <u>
                    <strong>
                      FASE 0: Organización interna institucional del proceso de
                      rendición de cuentas
                    </strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase0_1_2022}>
                      Conformación del equipo responsable de la implementación
                      del proceso de rendición de cuentas.
                    </a>
                  </li>

                  <li>
                    <a href={fase0_2_2022}>
                      Diseño de la propuesta y las herramientas necesarias para
                      su desarrollo.
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>
          <Accordion
            title="Fase 1"
            content={
              <>
                <p>
                  <u>
                    <strong>FASE 1: Elaboración del informe de RC</strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase1_1_2022}>
                      Consolidación de la información cuantitativa y cualitativa
                      de la gestión anual y evaluación de los resultados
                      alcanzados en el período fiscal concluido.
                    </a>
                  </li>

                  <li>
                    <a href={fase1_2_2022}>
                      Apertura de canales de comunicación virtual y presencial
                      para recabar solicitudes de información en el proceso de
                      rendición de cuentas.
                    </a>
                  </li>
                  <li>
                    <a href={fase1_3_2022}>
                      Recepción y análisis de la información.
                    </a>
                  </li>
                  <li>
                    <a href={fase1_4_2022}>
                      Carga de la información en el formulario de Rendición de
                      Cuentas y redacción del Informe de Rendición de Cuentas.
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>

          <Accordion
            title="Fase 2"
            content={
              <>
                <p>
                  <u>
                    <strong>
                      FASE 2: Deliberación sobre el Informe de RC presentado por
                      la autoridad a la ciudadanía.
                    </strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase2_1_2022}>
                      Difusión del Informe de Rendición de Cuentas 2022.
                    </a>
                  </li>

                  <li>
                    <a href={fase2_2_2022}>
                      Convocatoria pública y abierta a la presentación pública
                      del Informe de Rendición de Cuentas 2022.
                    </a>
                  </li>
                  <li>
                    <a href={fase2_3_2022}>
                      Deliberación pública presencial y con transmisión en vivo
                      del Informe de Rendición de Cuentas 2022.
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/utpl.loja/videos/1414945035933464/">
                      Difusión del video de deliberación pública de la Rendición
                      de Cuentas, formulario de rendición de cuentas e Informe
                      de Rendición de Cuentas para receptar aportes ciudadanos.
                      .
                    </a>
                  </li>

                  <li>
                    <a href={fase2_5_2022}>
                      Sistematización de los aportes ciudadanos recibidos y
                      elaboración del Acta de Compromiso. .
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>

          <Accordion
            title="Fase 3"
            content={
              <>
                <p>
                  <u>
                    <strong>
                      FASE 3: Entrega del Informe de Rendición de Cuentas al
                      CPCCS
                    </strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase3_1_2022}>
                      Entrega del Informe de Rendición de Cuentas al CPCCS.
                    </a>
                  </li>

                  <li>
                    <a href={fase3_2_2022}>
                      Entrega del Informe de Rendición de Cuentas a los órganos
                      de control del Estado.
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>
        </div>
      </div>

      <div className="layout8 ">
        <h2>Periodo 2021</h2>
        <div >
          <ul className="padding2">
            <li>
              <a href={informeanual_2021}>Informe anual</a>
            </li>
            <li>
              <a href={formulario_2021}>
                Formulario del Consejo de Participación Ciudadana y Control
                Social
              </a>
            </li>
          </ul>
          <Accordion
            title="Fase 0"
            content={
              <>
                {" "}
                <p>
                  <u>
                    <strong>
                      FASE 0: Organización interna institucional del proceso de
                      rendición de cuentas
                    </strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase0_1_2021}>
                      Conformación del equipo responsable de la implementación
                      del proceso de rendición de cuentas.
                    </a>
                  </li>

                  <li>
                    <a href={fase0_2_2021}>
                      Diseño de la propuesta y las herramientas necesarias para
                      su desarrollo.
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>
          <Accordion
            title="Fase 1"
            content={
              <>
                <p>
                  <u>
                    <strong>FASE 1: Elaboración del informe de RC</strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase1_1_2021}>
                      Consolidación de la información cuantitativa y cualitativa
                      de la gestión anual y evaluación de los resultados
                      alcanzados en el período fiscal concluido.
                    </a>
                  </li>

                  <li>
                    <a href={fase1_2_2021}>
                      Apertura de canales de comunicación virtual y presencial
                      para recabar solicitudes de información en el proceso de
                      rendición de cuentas.
                    </a>
                  </li>
                  <li>
                    <a href={fase1_3_2021}>
                      Recepción y análisis de la información.
                    </a>
                  </li>
                  <li>
                    <a href={fase1_4_2021}>
                      Carga de la información en el formulario de Rendición de
                      Cuentas y redacción del Informe de Rendición de Cuentas.
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>

          <Accordion
            title="Fase 2"
            content={
              <>
                <p>
                  <u>
                    <strong>
                      FASE 2: Deliberación sobre el Informe de RC presentado por
                      la autoridad a la ciudadanía.
                    </strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase2_1_2021}>
                      Difusión del Informe de Rendición de Cuentas 2022.
                    </a>
                  </li>

                  <li>
                    <a href={fase2_2_2021}>
                      Convocatoria pública y abierta a la presentación pública
                      del Informe de Rendición de Cuentas 2022.
                    </a>
                  </li>
                  <li>
                    <a href={fase2_3_2021}>
                      Deliberación pública presencial y con transmisión en vivo
                      del Informe de Rendición de Cuentas 2022.
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/utpl.loja/videos/306442491411314">
                      Difusión del video de deliberación pública de la Rendición
                      de Cuentas, formulario de rendición de cuentas e Informe
                      de Rendición de Cuentas para receptar aportes ciudadanos.
                      .
                    </a>
                  </li>

                  <li>
                    <a href={fase2_5_2021}>
                      Sistematización de los aportes ciudadanos recibidos y
                      elaboración del Acta de Compromiso. .
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>

          <Accordion
            title="Fase 3"
            content={
              <>
                <p>
                  <u>
                    <strong>
                      FASE 3: Entrega del Informe de Rendición de Cuentas al
                      CPCCS
                    </strong>
                  </u>
                </p>
                <ol>
                  <li>
                    <a href={fase3_1_2021}>
                      Entrega del Informe de Rendición de Cuentas al CPCCS.
                    </a>
                  </li>

                  <li>
                    <a href={fase3_2_2021}>
                      Entrega del Informe de Rendición de Cuentas a los órganos
                      de control del Estado.
                    </a>
                  </li>
                </ol>
              </>
            }
          ></Accordion>
        </div>
      </div>

      <div className="padding"></div>
    </>
  );
}

export default Rendicion;
