import "../css/conectus.css";
import { motion } from "framer-motion";

import image2 from "../img/social/facebook.png";
import image3 from "../img/social/instagram.png";
import image4 from "../img/social/tiktok.png";
import image5 from "../img/social/twitter.png";
import image6 from "../img/social/youtube.png";
import image7 from "../img/social/flickr.png";
import image8 from "../img/social/in.png";

function ConectUs(props) {
  let instagram = "";
  let facebook = "";
  let tiktok = "";
  let linkedin = "";
  let x = "";
  let youtube = "";
  let flickr = "";

  if (props.instagram) {
    instagram = (
      <>
        <a href={props.instagram}>
          <img src={image3} alt="utpl"></img>
        </a>
      </>
    );
  }

  if (props.facebook) {
    facebook = (
      <>
        <a href={props.facebook}>
          <img src={image2} alt="utpl"></img>
        </a>
      </>
    );
  }

  if (props.tiktok) {
    tiktok = (
      <>
        <a href={props.tiktok}>
          <img src={image4} alt="utpl"></img>
        </a>
      </>
    );
  }

  if (props.linkedin) {
    linkedin = (
      <>
        <a href={props.linkedin}>
          <img src={image8} alt="utpl"></img>
        </a>
      </>
    );
  }

  if (props.x) {
    x = (
      <>
        <a href={props.x}>
          <img src={image5} alt="utpl"></img>
        </a>
      </>
    );
  }

  if (props.youtube) {
    youtube = (
      <>
        <a href={props.youtube}>
          <img src={image6} alt="utpl"></img>
        </a>
      </>
    );
  }

  if (props.flickr) {
    flickr = (
      <>
        <a href={props.flickr}>
          <img src={image7} alt="utpl"></img>
        </a>
      </>
    );
  }

  return (
    <>
      <div className="wrapper-conectus padding">
        <div className="layout8">
          <h2>
            <motion.h2
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0,
                },
              }}
            >
              <b>Síguenos</b>
            </motion.h2>
          </h2>

          <p>{props.lema}</p>

          <div className="wrapper-social">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.1,
                },
              }}
            >
              {facebook}
            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                },
              }}
            >
              {instagram}
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              {tiktok}
            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                },
              }}
            >
              {x}
            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
            >
              {flickr}
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.6,
                },
              }}
            >
              {youtube}
            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.7,
                },
              }}
            >
              {linkedin}
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConectUs;
