import "../css/blockcolumn.css";
import { motion } from "framer-motion";
function Block3ColumnsText(props) {
  return (
    <div className="block-columntext block-column">
      <div className="layout8">
        <div className="col3">
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.5,
              },
            }}
          >
            <div className="layout9 content">{props.col1}</div>
          </motion.div>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
            }}
          >
            <div className="layout9 content">{props.col2}</div>
          </motion.div>

          <motion.div
            initial={{ x: -50, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1.5,
            }}
          >
            <div className="layout9 content">{props.col3}</div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Block3ColumnsText;
