import "../css/blockimagecabecera.css";
import { motion } from "framer-motion";

function Cabecera(props) {
  function TagExist(tag) {
    if (tag)
      return (
        <div className="tag">
          <span className="material-icons"> circle</span>
          <p>{tag}</p>
        </div>
      );
  }

  function IconModalidad(modalidad) {
    if (modalidad)
      return (
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          whileInView={{
            x: 1,
            opacity: 1,
            transition: {
              delay: 1,
            },
          }}
        >
          <span className="material-icons">computer</span>
          <p>{modalidad}</p>
        </motion.div>
      );
  }

  function IconTipo(tipo) {
    if (tipo)
      return (
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          whileInView={{
            x: 1,
            opacity: 1,
            transition: {
              delay: 1.3,
            },
          }}
        >
          <span className="material-icons">school</span>
          <p>{tipo}</p>
        </motion.div>
      );
  }

  function IconDuracion(duracion) {
    if (duracion)
      return (
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          whileInView={{
            x: 1,
            opacity: 1,
            transition: {
              delay: 1.6,
            },
          }}
        >
          <span className="material-icons">schedule</span>
          <p>{duracion}</p>
        </motion.div>
      );
  }

  function IconCosto(costo) {
    if (costo)
      return (
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          whileInView={{
            x: 1,
            opacity: 1,
            transition: {
              delay: 1.9,
            },
          }}
        >
          <span className="material-icons">attach_money</span>
          <p>{costo}</p>
        </motion.div>
      );
  }

  return (
    <>
      <div className="blockimagecabecera-internas">
        <div className="content bluebg">
          <div className="layout8">
            <div>
              <motion.div
                initial={{ x: -50, opacity: 0 }}
                whileInView={{
                  x: 1,
                  opacity: 1,
                  transition: {
                    delay: 2.2,
                  },
                }}
              >
                {TagExist(props.tag)}
              </motion.div>

              <motion.div
                initial={{ scale: 0.7, opacity: 0 }}
                whileInView={{
                  scale: 1,
                  opacity: 1,
                  transition: {
                    delay: 0.1,
                    duration: 0.4,
                  },
                }}
              >
                {props.title}
              </motion.div>
              <div className="icons">
                {IconModalidad(props.modalidad)}
                {IconTipo(props.tipo)}
                {IconDuracion(props.duracion)}
                {IconCosto(props.costo)}
              </div>
            </div>
          </div>
        </div>
        <div className="imagen">
          {" "}
          <img src={props.src} alt="utpl"></img>{" "}
        </div>
      </div>
    </>
  );
}

export default Cabecera;
