import { NavLink } from "react-router-dom";
import Breadcumbs from "../../components/Breadcumbs";
import useContentful from "../../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { motion } from "framer-motion";
import BlockRightImage from "../../components/BlockRightImage";
import Accordion from "../../components/Accordion";
import Notification from "../../components/Notification";
import Block4Columns from "../../components/Block4ColumnsText";
import ModalFixed from "../../components/ModalFixed";
import Modal from "../../components/Modal";
import Flipcard from "../../components/Flipcard";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";

import cambridge from "../../img/logosingles/cambridge.webp";
import berlitz from "../../img/logosingles/berlitz.webp";
import global from "../../img/logosingles/global.webp";
import alianza from "../../img/logosingles/alianza.webp";
import dante from "../../img/logosingles/dante.webp";
import evaluacion from "../../img/logosingles/evaluacion.png";
import certificado from "../../img/logosingles/certificado.png";
import titulos from "../../img/logosingles/titulos.png";
import instituciones from "../../img/logosingles/instituciones.png";
import Gallery from "../../components/Gallery";

const query = `
query 
{
    videoTemplate(id: "5Mq3o4JnDWYUJnz8KscUQ3") {
    image{url, title}
      contentBlock1{json}
      imageBlock2{url, title}
      contentBlock2{json}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      contentBlock5{json}
      contentBlock6{json}
      contentBlock7{json}
      contentBlock8{json}
      imageBlock9{url, title}
      contentBlock9{json}
      contentBlock10{json}
      galleryCollection{
      items{url title}
      }
      gallery2Collection{
      items{url title}
      }
      gallery3Collection{
      items{url title}
      }

         gallery4Collection{
      items{url title}
      }

  
}
}
`;

function SegundaLengua() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <BlockImageCabecera
          src={data.videoTemplate.image.url}
          title={documentToReactComponents(
            data.videoTemplate.contentBlock1.json
          )}
        ></BlockImageCabecera>
      </div>
      <div className="bluebg">
        <Breadcumbs
          content={
            <>
              <NavLink to="/segunda-lengua">Segunda Lengua</NavLink>
            </>
          }
        ></Breadcumbs>

        <center>
          <div className="layout8">
            <div className="width500">
              <h2>
                Para demostrar la suficiencia en una segunda lengua,
                <b> debes considerar una de las siguientes opciones:</b>
              </h2>
            </div>
          </div>
        </center>
        <div className="segunda-lengua">
          <Block4Columns
            col1={
              <a href="#examen" className="icon20">
                <center>
                  <img src={evaluacion}></img>
                </center>
                <h4>Rendir un examen de ubicacion</h4>
                <motion.div
                  className="icons"
                  initial={{ y: 10 }}
                  animate={{ y: 0, opacity: 0.8 }}
                  transition={{ type: "spring", repeat: Infinity }}
                >
                  <span className="material-icons">keyboard_arrow_down</span>
                </motion.div>
              </a>
            }
            col2={
              <a href="#certificados" className="icon20">
                <center>
                  <img src={certificado}></img>
                </center>
                <h4>Reconocer certificados de otras instituciones</h4>
                <motion.div
                  className="icons"
                  initial={{ y: 10 }}
                  animate={{ y: 0, opacity: 0.8 }}
                  transition={{ type: "spring", repeat: Infinity }}
                >
                  <span className="material-icons">keyboard_arrow_down</span>
                </motion.div>
              </a>
            }
            col3={
              <a href="#titulos">
                <div className="icon20">
                  <center>
                    <img src={titulos}></img>
                  </center>
                </div>
                <h4>Homologar títulos de tercer y cuarto nivel </h4>
                <motion.div
                  className="icons"
                  initial={{ y: 10 }}
                  animate={{ y: 0, opacity: 0.8 }}
                  transition={{ type: "spring", repeat: Infinity }}
                >
                  <span className="material-icons">keyboard_arrow_down</span>
                </motion.div>
              </a>
            }
            col4={
              <a href="#instituciones" className="icon20">
                <center>
                  <img src={instituciones}></img>
                </center>
                <h4>Prepararte en instituciones externas aliadas a la UTPL</h4>
                <motion.div
                  className="icons"
                  initial={{ y: 10 }}
                  animate={{ y: 0, opacity: 0.8 }}
                  transition={{ type: "spring", repeat: Infinity }}
                >
                  <span className="material-icons">keyboard_arrow_down</span>
                </motion.div>
              </a>
            }
          ></Block4Columns>
        </div>
      </div>

      <div id="examen">
        <div className="relative">
          <BlockLeftImage
            src={data.videoTemplate.imageBlock2.url}
            content={
              <>
                <div className="icon20">
                  <img src={evaluacion}></img>
                </div>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock2.json
                )}
              </>
            }
          ></BlockLeftImage>
          <ModalFixed
            color="#4e975b"
            title="Proceso de inscripción 📝"
            content={
              <>
                <Gallery
                  gallery={data.videoTemplate.galleryCollection.items}
                  width="400"
                ></Gallery>
              </>
            }
          ></ModalFixed>
        </div>
        <div className="relative wrapper-blockimage">
          <div className="content">
            <div className="layout8 padding2 ">
              {documentToReactComponents(data.videoTemplate.contentBlock3.json)}
            </div>
          </div>

          <ModalFixed
            color="#4e975b"
            title="Proceso de registro ✔"
            content={
              <>
                {" "}
                <Gallery
                  gallery={data.videoTemplate.gallery2Collection.items}
                  width="400"
                ></Gallery>
              </>
            }
          ></ModalFixed>
        </div>
      </div>

      <div className="graybg" id="certificados">
        <div className="relative">
          <BlockRightImage
            src={data.videoTemplate.imageBlock4.url}
            content={
              <>
                <div className="icon20">
                  <img src={certificado}></img>
                </div>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock4.json
                )}

                <Accordion
                  title="Certificados de Lenguas Ancestrales y Lenguas de Señas"
                  content={documentToReactComponents(
                    data.videoTemplate.contentBlock5.json
                  )}
                ></Accordion>
                <Accordion
                  title="Certificados nacionales e internacionales"
                  content={documentToReactComponents(
                    data.videoTemplate.contentBlock6.json
                  )}
                ></Accordion>
                <Accordion
                  title="Certificados de instituciones de educación superior"
                  content={documentToReactComponents(
                    data.videoTemplate.contentBlock7.json
                  )}
                ></Accordion>
                <br></br>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock8.json
                )}
              </>
            }
          ></BlockRightImage>
          <ModalFixed
            color="#6f56c1"
            title="Proceso de homologación ✍🏼️"
            content={
              <>
                <Gallery
                  gallery={data.videoTemplate.gallery3Collection.items}
                  width="400"
                ></Gallery>
              </>
            }
          ></ModalFixed>{" "}
        </div>
      </div>

      <div id="titulos" className="relative">
        <BlockLeftImage
          src={data.videoTemplate.imageBlock9.url}
          content={
            <>
              <div className="icon20">
                <img src={titulos}></img>
              </div>
              {documentToReactComponents(data.videoTemplate.contentBlock9.json)}
            </>
          }
        ></BlockLeftImage>
           <ModalFixed
            color="#186150"
            title="Proceso de homologación ✍🏼️"
            content={
              <>
                <Gallery
                  gallery={data.videoTemplate.gallery4Collection.items}
                  width="400"
                ></Gallery>
              </>
            }
          ></ModalFixed>{" "}
      </div>

      <div id="instituciones" className="relative graybg ">
        <div className="layout8 padding">
          <div className="icon20">
            <img src={instituciones}></img>
          </div>
          {documentToReactComponents(data.videoTemplate.contentBlock10.json)}

          <div className=" layout8 ">
            <Accordion
              title="Idioma Inglés"
              content={
                <div className="icon100">
                  <div>
                    <BlockLeftImage
                      src={cambridge}
                      content={
                        <>
                          <ul>
                            <li>
                              <p>Examen de ubicación con costo</p>
                            </li>
                            <li>
                              <p>Modalidad a distancia</p>
                            </li>
                            <li>
                              <p>
                                <b>Contacto:</b> Jonathan Ramón, 0987590034,
                                jramon@booksandbits.com.ec
                              </p>
                            </li>
                          </ul>
                        </>
                      }
                    ></BlockLeftImage>{" "}
                  </div>

                  <div>
                    <BlockRightImage
                      src={berlitz}
                      content={
                        <>
                          <ul>
                            <li>
                              <p>Examen de ubicación gratuito</p>
                            </li>
                            <li>
                              <p>Modalidad a distancia</p>
                            </li>
                            <li>
                              <p>
                                <b>Contacto:</b> Erika Castillo, 0987236738,
                                coordinarloja@berlitz.com.ec
                              </p>
                            </li>
                          </ul>
                        </>
                      }
                    ></BlockRightImage>
                    <div>
                      <BlockLeftImage
                        src={global}
                        content={
                          <>
                            <ul>
                              <li>
                                <p>Examen de ubicación gratuito</p>
                              </li>
                              <li>
                                <p>Modalidad a distancia</p>
                              </li>
                              <li>
                                <p>
                                  <b>Contacto:</b> Adriano Muñoz Torres,
                                  0939056187,
                                  secretariaglobalquality@gmail.com
                                </p>
                              </li>
                            </ul>
                          </>
                        }
                      ></BlockLeftImage>{" "}
                    </div>
                  </div>
                </div>
              }
            ></Accordion>
            <Accordion
              title="Idioma Frances"
              content={
                <div className="icon100">
                  <BlockLeftImage
                    src={alianza}
                    content={
                      <>
                        <ul>
                          <li>
                            <p>Examen de ubicación con costo</p>
                          </li>
                          <li>
                            <p>Modalidad presencial en Loja y a distancia.</p>
                          </li>
                          <li>
                            <p>
                              <b>Contacto:</b> Camille Hannequart, 0969365220,
                              direccion@af-loja.com
                            </p>
                          </li>
                        </ul>
                      </>
                    }
                  ></BlockLeftImage>{" "}
                </div>
              }
            ></Accordion>
            <Accordion
              title="Idioma Italiano"
              content={
                <div className="icon100">
                  <BlockLeftImage
                    src={dante}
                    content={
                      <>
                        <ul>
                          <li>
                            <p>Examen de ubicación con costo</p>
                          </li>
                          <li>
                            <p>Modalidad a distancia.</p>
                          </li>
                          <li>
                            <p>
                              <b>Contacto:</b> Sandra Guerrón, 0979051299,
                              secretaria@dantequito.com
                            </p>
                          </li>
                        </ul>
                      </>
                    }
                  ></BlockLeftImage>{" "}
                </div>
              }
            ></Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

export default SegundaLengua;
