import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import Accordion from "../components/Accordion";
function Imagen() {
  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/imagen">Imagen</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="layout8  manual-imagen">
        <center>
          <h2 className="layout6">
            Recursos de <b>imagen y comunicación institucionales</b>
          </h2>
        </center>
        <div className="icon200">
          <h3>
            Logo <b>institucional</b>{" "}
          </h3>

          <div className="flex">
            <div>
              {" "}
              <img src="https://utpl.edu.ec/recursos/img/utpl1.png" alt="utpl"></img>
              <br></br>
              <a href="https://utpl.edu.ec/recursos/img/utpl.ai">Descargar .ai</a>
            </div>

            <img src="https://utpl.edu.ec/recursos/img/utpl2.png" alt="utpl"></img>
            <img src="https://utpl.edu.ec/recursos/img/utpl3.png" alt="utpl"></img>
          </div>
        </div>

        <div className="padding2">
          <h3>
            Logo <b>publicitario</b>
          </h3>
          <div className="flex icon200 ">
            <div>
              <img src="https://utpl.edu.ec/recursos/img/utpl_logo1.png" alt="utpl"></img>
              <br></br>
              <a href="https://utpl.edu.ec/recursos/img/utplpublicitario.ai">
                Descargar .ai
              </a>
            </div>

            <img src="https://utpl.edu.ec/recursos/img/utpl_logo2.png" alt="utpl"></img>
          </div>
        </div>
      </div>

      <div className="graybg padding">
        <div className="layout8">
        <h3>Otros recursos</h3>
        <Accordion
          title="Plantillas word"
          content={
            <>
              <a href="https://utpl.edu.ec/recursos/docs/plantilla_general.docx">
                Plantilla general
              </a>

              <a href="https://utpl.edu.ec/recursos/docs/plantilla_membretada.docx">
                Hoja membretada
              </a>
            </>
          }
        ></Accordion>
        <Accordion
          title="Plantillas ppt"
          content={
            <>
              <a href="https://utpl.edu.ec/recursos/docs/plantilla_general.pptx">
                Plantilla general
              </a>
            </>
          }
        ></Accordion>

        <Accordion
          title="Fondos"
          content={
            <>
              <a href="https://utpl.edu.ec/recursos/img/fondo1.png">
               Fondo 1
              </a>
              <a href="https://utpl.edu.ec/recursos/img/fondo2.png">
               Fondo 2
              </a>
            </>
          }
        ></Accordion>
      </div></div>
    </>
  );
}
export default Imagen;
