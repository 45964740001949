import useContentful from "../../hooks/use-contenful";
import Flipcard from "../../components/Flipcard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Accordion from "../../components/Accordion";

const query = `
query 
{
    videoTemplate(id: "6ADlPw8bee0tqVRRqVjOo2") {
      contentBlock1{json}
      imageBlock2{url, title}
      contentBlock2{json}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}

      contentBlock7{json}
      imageBlock8{url, title}
      contentBlock8{json}
      contentBlock9{json}

      contentBlock10{json}
      imageBlock11{url, title}
      contentBlock11{json}
      contentBlock12{json}
     
     

      
     
    }
  
}
`;

function Autoridadesacademicas2() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="gobernanza-autoridades ">
        <div className="layout8">
    

          <div className="wrapper-autoridades-academicas">
            
            <div>
            {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
              <Flipcard
                src={data.videoTemplate.imageBlock2.url}
                alt={data.videoTemplate.imageBlock2.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock2.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock3.json
                )}
              ></Accordion>
            </div>
            
        
            
            <div>
            {documentToReactComponents(data.videoTemplate.contentBlock4.json)}
              <Flipcard
                src={data.videoTemplate.imageBlock5.url}
                alt={data.videoTemplate.imageBlock5.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock5.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock6.json
                )}
              ></Accordion>
            </div>
            

            <div>
            {documentToReactComponents(data.videoTemplate.contentBlock7.json)}
              <Flipcard
                src={data.videoTemplate.imageBlock8.url}
                alt={data.videoTemplate.imageBlock8.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock8.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock9.json
                )}
              ></Accordion>
            </div>

            
            <div>
            {documentToReactComponents(data.videoTemplate.contentBlock10.json)}
              <Flipcard
                src={data.videoTemplate.imageBlock11.url}
                alt={data.videoTemplate.imageBlock11.title}
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock11.json
                )}
              ></Flipcard>

              <Accordion
                title="Autoridades"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock12.json
                )}
              ></Accordion>
            </div>

            
          </div>
        </div>
      </div>
    </>
  );
}

export default Autoridadesacademicas2;
