import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import { motion } from "framer-motion";
import BlockAcademicOffer from "../components/BlockAcademicOffer";
function Decidesermas() {
  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/decidesermas">Decide ser más</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>

      <div className="layout8">
        <center>
          <h2 className="tag-sermas">
            Te unes al reto de <b>ser más</b>
          </h2>
        </center>
      </div>
      <BlockAcademicOffer></BlockAcademicOffer>
    </>
  );
}

export default Decidesermas;
