import Block3ColumnsText from "../../components/Block3ColumnsText";

import BlockLeftImage from "../../components/BlockLeftImage";
import BlockRightImage from "../../components/BlockRightImage";
import Flipcard from "../../components/Flipcard";

function Validacioninternacional() {
  return (
    <>
      <div className="margin">
        <div className="layout8 padding">
          <h2 className="width500">
            Estudia una carrera o posgrado en la UTPL y
            <b> valida tu título en Estados Unidos</b>
          </h2>

          <p>
            Validar tu título en Estados Unidos amplía significativamente tus
            oportunidades profesionales, posicionándote como un candidato
            altamente competitivo en el mercado laboral global. Este proceso te
            ayudará a desarrollar habilidades esenciales en comunicación
            intercultural y resolución de problemas. Además, te brindará la
            oportunidad de establecer conexiones valiosas con profesionales y
            académicos internacionales, enriqueciendo tu red profesional
          </p>
        </div>
      </div>

      <div className="graybg padding2">
        <center>
          <h2>Beneficios</h2>
        </center>
        <Block3ColumnsText
          col1={
            <>
              <h3>
                Acceso a un mayor{" "}
                <b>número de oportunidades en el mercado laboral global</b>{" "}
              </h3>
            </>
          }
          col2={
            <>
              <h3>
                Mayor <b>reconocimiento profesional</b>
              </h3>
            </>
          }
          col3={
            <>
              <h3>Facilidad para realizar trámites migratorios</h3>{" "}
            </>
          }
        ></Block3ColumnsText>
      </div>
    </>
  );
}

export default Validacioninternacional;
