
import "../css/blockimagecabecera.css";

function BlockImageCabecera(props) {
  function TagExist(tag) {
    if (tag)
      return (
        <div className="tag">
          <span className="material-icons"> circle</span>
          <p>{tag}</p>
        </div>
      );
  }

  function IconModalidad(modalidad) {
    if (modalidad)
      return (
        <div>
          <span className="material-icons">computer</span>
          <p>{modalidad}</p>
        </div>
      );
  }

  function IconTipo(tipo) {
    if (tipo)
      return (
        <div>
          <span className="material-icons">school</span>
          <p>{tipo}</p>
        </div>
      );
  }

  function IconDuracion(duracion) {
    if (duracion)
      return (
        <div>
          <span className="material-icons">schedule</span>
          <p>{duracion}</p>
        </div>
      );
  }

  function IconCosto(costo) {
    if (costo)
      return (
        <div>
          <span className="material-icons">attach_money</span>
          <p>{costo}</p>
        </div>
      );
  }


  return (
    <>
      <div className="blockimagecabecera">
        <img src={props.src} alt="utpl"></img>
        <div className="wrapper-blockimagecabecera">
          <div>
            <div>
              {TagExist(props.tag)}
              {props.title}
              <div className="icons">
                {IconModalidad(props.modalidad)}
                {IconTipo(props.tipo)}
                {IconDuracion(props.duracion)}
                {IconCosto(props.costo)}
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockImageCabecera;
