import "../css/flipcard.css";
function Flipcard(props) {
  return (
    <>
      <div class="flip-card">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src={props.src} alt={props.alt}/>
          </div>
          <div class="flip-card-back bluebg">
            <div className="layout9"> {props.content}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Flipcard;
