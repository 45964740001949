import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Select from "react-select";
import FormacionPermanenteVista from "./FormacionPermanenteVista";

function FormacionPermanenteOffer(props) {
  const [dataarea, setDataArea] = useState("");
  const [facultad, setFacultad] = useState("");
  const [organizan, setOrganizan] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [modalidad, setModalidad] = useState("");

  const updateWindowDimensions = () => {
    const newW = window.innerWidth;
    setWidth(newW);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  function handleChange(e) {
    const { value, checked } = e.target;
    if (checked) {
      setFacultad(value);
    } else {
      setFacultad("0");
    }
  }

  function handleChangeMobile(value) {
    setFacultad(value);
  }

  function handleChangeModalidad(e) {
    const { value, checked } = e.target;

    if (checked) {
      setModalidad(value);
    } else {
      setModalidad(0);
    }
  }

  function handleChangeModalidadMobile(data) {
    setModalidad(data);
  }
  function handleChangeOrganizanMobile(data) {
    setOrganizan(data);
  }
  function handleChangeOrganizan(e) {
    const { value, checked } = e.target;

    if (checked) {
      setOrganizan(value);
    } else {
      setOrganizan(0);
    }
  }
  const optionsmodalidad = [
    { value: "En línea", label: "En línea" },
    { value: "Semipresencial", label: "Semipresencial" },

    { value: "0", label: "-" },
  ];

  const optionsfacultad = [
    {
      value: "Ciencias Sociales",
      label: "Ciencias Sociales",
    },
    {
      value: "Desarrollo Sostenible",
      label: "Desarrollo Sostenible",
    },
      {
      value: "Desarrollo Personal",
      label: "Desarrollo Personal",
    },
    {
      value: "Negocios y Administración",
      label: "Negocios y Administración",
    },
    {
      value: "Salud y Bienestar",
      label: "Salud y Bienestar",
    },
    {
      value: "Industria y Construcción",
      label: "Industria y Construcción",
    },
    {
      value: "Informática y Tecnología",
      label: "Informática y Tecnología",
    },
    { value: "0", label: "-" },
  ];

  const optionsorganizan = [
    {
      value: "Cátedra Unesco UTPL",
      label: "Cátedra Unesco",
    },
    {
      value: "EDES UTPL",
      label: "EDES",
    },
    {
      value: "UTPL Misiones Universitarias",
      label: "UTPL Misiones Universitarias",
    },
    {
      value: "UTPL TEC",
      label: "UTPL TEC",
    },

    { value: "0", label: "-" },
  ];

  return (
    <div className="layout9">
      <div className="wrapper-titleoffer ">
        <motion.div
          initial={{ y: 200, opacity: 0 }}
          whileInView={{
            y: 0,
            opacity: 1,
            transition: {
              type: "spring",
              stiffness: 200,
            },
          }}
        >
          {props.title}
        </motion.div>
      </div>
      <h2>{props.subtitle}</h2>
      <div className="offerbymodelo">
        {width > 900 && (
          <div>
            <div className="layout9 ">
              <p>
                <b>Categoría</b>
              </p>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="0"
                  id="todas"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="todas">
                  Todas
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Ciencias Sociales"
                  id="Ciencias Sociales"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="Ciencias Sociales">
                  Ciencias Sociales
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Desarrollo Sostenible"
                  id="Desarrollo Sostenible"
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="Desarrollo Sostenible"
                >
                  Desarrollo Sostenible
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Negocios y Administración"
                  id="negocios"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="negocios">
                  Negocios y Administración
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Salud y Bienestar"
                  id="salud"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="salud">
                  Salud y Bienestar
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Industria y Construcción"
                  id="industria"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="industria">
                  Industria y Construcción
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="languages"
                  value="Informática y Tecnología"
                  id="informatica"
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="informatica">
                  Informática y Tecnología
                </label>
              </div>

              <p>
                <b>Modalidad</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="0"
                  id="modalidad"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="modalidad">
                  Todas
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="En línea"
                  id="linea"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="linea">
                  En línea
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="modalidad"
                  value="Semipresencial"
                  id="semipresencial"
                  onChange={handleChangeModalidad}
                />
                <label className="form-check-label" htmlFor="semipresencial">
                  Semipresencial
                </label>
              </div>

              <p>
                <b>Organizan</b>
              </p>
              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="0"
                  id="organizantodos"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="organizantodos">
                  Todas
                </label>
              </div>

              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="Cátedra Unesco UTPL"
                  id="catedra"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="catedra">
                  Cátedra Unesco
                </label>
              </div>
              {/* 
              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="CIMA UTPL"
                  id="cima"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="cima">
                  CIMA
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="ESCOP UTPL"
                  id="escop"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="escop">
                  ESCOP
                </label>
              </div>*/}
              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="EDES UTPL"
                  id="edes"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="edes">
                  EDES
                </label>
              </div>

              {/* 
              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="ILFAM"
                  id="ilfam"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="ilfam">
                  ILFAM
                </label>
              </div>
*/}

              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="UTPL Misiones Universitarias"
                  id="misiones"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="misiones">
                  UTPL Misiones Universitarias
                </label>
              </div>
              <div className="item">
                <input
                  type="radio"
                  name="organizan"
                  value="UTPL TEC"
                  id="tec"
                  onChange={handleChangeOrganizan}
                />
                <label className="form-check-label" htmlFor="tec">
                  UTPL TEC
                </label>
              </div>

              <p>
                <b>Área de interés</b>
              </p>

              <div className="wrapper-inputoffer">
                <input
                  className="input-academicoffer"
                  name="area"
                  value={dataarea}
                  onChange={(e) => setDataArea(e.target.value)}
                  placeholder="Ejemplo: empresas"
                ></input>
                <span className="material-icons">search</span>
              </div>
            </div>
          </div>
        )}

        {width < 900 && (
          <div>
            <p>
              <b>Categoría</b>
            </p>
            <Select
              options={optionsfacultad}
              defaultValue={facultad}
              onChange={handleChangeMobile}
            ></Select>

            <p>
              <b>Modalidad</b>
            </p>
            <Select
              options={optionsmodalidad}
              defaultValue={modalidad}
              onChange={handleChangeModalidadMobile}
            ></Select>

            <p>
              <b>Organizan</b>
            </p>
            <Select
              options={optionsorganizan}
              defaultValue={organizan}
              onChange={handleChangeOrganizanMobile}
            ></Select>
            <p>
              <b>Área de interés</b>
            </p>
            <div className="wrapper-inputoffer">
              <input
                className="input-academicoffer"
                name="area"
                value={dataarea}
                onChange={(e) => setDataArea(e.target.value)}
                placeholder="Ejemplo: empresas"
              ></input>
              <span className="material-icons">search</span>
            </div>
          </div>
        )}

        <div>
          {width > 900 && (
            <FormacionPermanenteVista
              title="Elige tu carrera"
              keyword={dataarea.trimEnd().toLowerCase()}
              modalidad={modalidad}
              facultad={facultad}
              organizan={organizan}
            ></FormacionPermanenteVista>
          )}
          {width < 900 && (
            <FormacionPermanenteVista
              title="Elige tu carrera"
              keyword={dataarea.trimEnd().toLowerCase()}
              facultad={facultad.value}
              modalidad={modalidad.value}
              organizan={organizan.value}
            ></FormacionPermanenteVista>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormacionPermanenteOffer;
