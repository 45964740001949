import BlockAcademicOffer from "../components/BlockAcademicOffer";
import FixedHeader from "../components/FixedHeader";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BannerGeneral from "../components/BannerGeneral";
import Block4ColumnsText from "../components/Block4ColumnsText";
import FixedNotification from "../components/FixedNotification";
import BlockLeftImage from "../components/BlockLeftImage";

import img1 from "../img/banner-titulo-valido.jpg";
import BlockRightImage from "../components/BlockRightImage";


function NewHome() {
  return (
    <>
      <FixedHeader
        video="https://utpl.edu.ec/recursos/videos/utpl.mp4"
        src="https://utpl.edu.ec/recursos/img/cabecera.jpg"
        span0="¡Bienvenidos"
        span1="a este entorno de"
        span2={
          <>
            {" "}
            aprendizaje <b>sostenible</b>,
          </>
        }
        span3=" dinámico e"
        span4="INNOVADOR!"
      ></FixedHeader>

      <BlockAcademicOffer></BlockAcademicOffer>

   
      {/*
      <BlockRightImage
        content={
          <>
            <h2>
              Impulsa tu futuro con un{" "}
              <b>título UTPL válido en Estados Unidos</b>
            </h2>
            <p>
              Accede a mayores oportunidades laborales y obtén el reconocimiento
              profesional que fortalecerá tu perfil en el mercado laboral
              internacional.{" "}
            </p>
            <p><b>¡Con la UTPL el mundo está a tu alcance!</b></p>

            <a href="/validacion-internacional">Más información</a>
          </>
        }
        src="https://eventos.utpl.edu.ec/sites/default/files/images/img-titulo-valido.png"
      ></BlockRightImage>
       
      <img src={img1}></img>
     
      <div className="graybg wrapper-simulador">
        <BlockLeftImage
          src={data.videoTemplate.imageBlock7.url}
          alt={data.videoTemplate.imageBlock7.title}
          content={documentToReactComponents(
            data.videoTemplate.contentBlock7.json
          )}
        ></BlockLeftImage>
      </div>*/}
      <div className="icon300 graybg">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>
              <h2>
                {" "}
                Simulador <b>de costos</b>
              </h2>
              <p>
                Aplica para estudiantes nuevos de carreras - tecnologías en
                línea y a distancia
              </p>
              <p>
                {" "}
                Explora los costos, las opciones de becas y variedad de
                descuentos diseñados para ti. ¡Haz tu matrícula ahora mismo y da
                el primer paso hacia una educación superior que se adapte a tus
                necesidades!
              </p>

              <a href="https://matriculas.utpl.edu.ec">Simular ahora</a>
            </>
          }
        ></BlockLeftImage>
      </div>


      <BlockBackgroundImage
        src="https://utpl.edu.ec/recursos/img/bg-centros.png"
        content={
          <>
            <h2>
              La UTPL se expande a nivel <b> nacional e internacional</b>
            </h2>
            <p>
              <b>Centros de apoyo UTPL</b>
            </p>
            <p>
              Comprometidos con brindarte una educación de calidad ampliamos
              nuestra cobertura a través de más de 89 centros de apoyo en
              diversos rincones del país y en 3 centros internacionales en
              Madrid, Roma y Nueva York donde encontrarás recursos y asistencia
              para tu desarrollo académico integral.
            </p>
            <p>
              Encuentra la ubicación de tu centro de apoyo más cercano en
              nuestro directorio institucional.
            </p>
            <p>
              <a target="_blank" href="https://directorio.utpl.edu.ec/centros">
                Haz clic aquí
              </a>
            </p>
          </>
        }
      ></BlockBackgroundImage>

      <div className="estudiar-utpl padding">
        <Block4ColumnsText
          col1={
            <>
              <h2>
                ¿Por qué estudiar <b>en la UTPL?</b>
              </h2>
              <p>
                Nuestros{" "}
                <b>más de 53 años de experiencia en formación universitaria</b>{" "}
                y más de 42 mil estudiantes matriculados de grado, posgrado,
                tecnologías y formación permanente avalan nuestra calidad
                académica y excelencia educativa.
              </p>
            </>
          }
          col2={
            <>
              <h3>
                Emprendimiento e <b>innovación</b>
              </h3>
              <p>
                Por quinto año consecutivo obtuvimos el reconocimiento como la{" "}
                <b>"Institución académica con mayor impacto en innovación"</b>{" "}
                por la Alianza para el Emprendimiento e Innovación (AEI).
              </p>
            </>
          }
          col3={
            <>
              <h3>
                Generación y <b>transferencia del conocimiento</b>
              </h3>
              <p>
                Promovemos un <b>modelo educativo innovador y emprendedor</b>{" "}
                donde nuestros estudiantes, mediante una gestión inteligente del
                conocimiento y la oferta tecnológica, resuelven problemáticas
                sociales y empresariales.
              </p>
              <p></p>
            </>
          }
          col4={
            <>
              <h3>
                Excelencia <b>reconocida a nivel mundial</b>
              </h3>
              <p>
                Nos ubicamos entre las{" "}
                <b>siete mejores universidades del país </b>en el ranking THE
                World University Rankings 2024, lo que certifica nuestra calidad
                en enseñanza, investigación, transferencia de conocimientos y
                perspectiva internacional.{" "}
              </p>
            </>
          }
        ></Block4ColumnsText>
      </div>

      <BannerGeneral></BannerGeneral>
      {/* 
      <FixedNotification
        link="https://programas.utpl.edu.ec/recuperos-utpl"
        content={
          <>
            <div>
              <p className="notification1">RETOMA</p>
              <p className="notification2">TUS ESTUDIOS</p>
            </div>
            <div>
              <div className="layout9">
                <p className="notification3">
                  Matricúlate ahora y obten un 30% de descuento.
                </p>
                <p className="notification4">
                  Conoce más{" "}
                  <span className="material-icons">chevron_right</span>
                </p>
              </div>
            </div>
          </>
        }
      ></FixedNotification>*/}
    </>
  );
}

export default NewHome;
