function ViewParagraph(props) {
  var paragraph = "";

  if (props.content) {
    paragraph = props.content.map((item) =>
      HandleInfor(item.type, item.children)
    );
  } else {
    paragraph = "";
  }

  function HandleInfor(type, data) {
    try {
      if (type === "paragraph") {
        return (
          <>
            <p>
              {data.map((item) => (
                <>
                  {!item.bold && <>{item.text}</>}

                  {item.bold && (
                    <>
                      <b> {item.text}</b>
                    </>
                  )}
                </>
              ))}
            </p>
          </>
        );
      }
      if (type === "list") {
        return (
          <>
            <ul>
              {data.map((item) => (
                <>
                  <li>
                    <p>
                      {item.children.map((item) => (
                        <>
                          {!item.bold && <>{item.text}</>}

                          {item.bold && (
                            <>
                              <b> {item.text}</b>
                            </>
                          )}
                        </>
                      ))}
                    </p>
                  </li>
                </>
              ))}
            </ul>
          </>
        );
      }
    } catch (error) {
      return "";
    }
  }
  return <>{paragraph}</>;
}

export default ViewParagraph;
