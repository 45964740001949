import logo from "../img/logoutpl.png";
import "../css/footer.css";

import Social from "./Social";

function Footer() {
  return (
    <div className="wrapper-footer">
      <img className="logo" src={logo} alt="utpl"></img>

      <p>
        <b>Conecta con nosotros: </b> 1800 88 75 88 - (07) 370 1444 -
        informacion@utpl.edu.ec
      </p>

      <div className="social">
        <div className="wrapper-social layout8 ">
          <Social></Social>
        </div>
      </div>

      <div className="wrapper-footerbottom">
        <div className="layout8">
          <a href="https://www.utpl.edu.ec/privacidad" target="_blank" rel="noreferrer">
            Política de privacidad
          </a>
          <p> - </p>
          <a href="https://www.utpl.edu.ec/terminos" target="_blank" rel="noreferrer">
            Términos y condiciones
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
