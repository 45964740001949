import { useQuery } from "@apollo/client";
import Breadcumbs from "../../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import Cabecera from "../../components/Cabecera";
import ViewParagraph from "../../components/ViewParagraph";
import Quote from "../../components/Quote";
import Accordion from "../../components/Accordion";
import Docentes from "../../components/Docentes";
import BlockLeftImage from "../../components/BlockLeftImage";

import {
  ViewPhoto,
  IsCampoAmplioConocimiento,
  IsModalidad,
  IsTipo,
  IsLink,
  QueryPosgrados,
  Hubspotutpl,
  ViewBrochure,
  IsResolucion,
} from "./Posgradoshooks";
import PosgradoPagos from "../../components/PosgradoPagos";
import ModalFixed from "../../components/ModalFixed";

const query = QueryPosgrados(4);

function MaestriaDerechoProcesal() {
  const { loading, error, data } = useQuery(query);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="posgrados-content">
      <div className="">
        <Cabecera
          src={ViewPhoto(
            data.posgradosOffer.data.attributes.Portada.data.attributes.url
          )}
          tag={IsCampoAmplioConocimiento(
            data.posgradosOffer.data.attributes.Campo
          )}
          modalidad={IsModalidad(data.posgradosOffer.data.attributes.Modalidad)}
          tipo={data.posgradosOffer.data.attributes.Tipomaestria}
          duracion={data.posgradosOffer.data.attributes.Duracion}
          costo={data.posgradosOffer.data.attributes.Precio}
          title={
            <>
              <h2>
                {IsTipo(data.posgradosOffer.data.attributes.Tipo)} en{" "}
                {data.posgradosOffer.data.attributes.Nombre}
              </h2>
              <p>{data.posgradosOffer.data.attributes.Lema}</p>

              {IsLink(data.posgradosOffer.data.attributes.EnlacePostulacion)}
            </>
          }
        ></Cabecera>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/posgrados">Posgrados</NavLink>
            <span class="material-icons">chevron_right</span>
            <NavLink to="/maestria-derecho-procesal">
              {IsTipo(data.posgradosOffer.data.attributes.Tipo)} en{" "}
              {data.posgradosOffer.data.attributes.Nombre}
            </NavLink>
          </>
        }
      ></Breadcumbs>
      <div className="relative">
        <div className="introduction-maestria layout8  ">
          <div>
            {IsResolucion(data.posgradosOffer.data.attributes.Resolucion1)}
            {IsResolucion(data.posgradosOffer.data.attributes.Resolucion2)}
            <ViewParagraph
              content={data.posgradosOffer.data.attributes.Descripcion1}
            ></ViewParagraph>
            <Accordion
              title="Requisitos de postulación"
              content={
                <ViewParagraph
                  content={data.posgradosOffer.data.attributes.Postulacion}
                ></ViewParagraph>
              }
            ></Accordion>
            <Quote
              content={data.posgradosOffer.data.attributes.Atributo1}
            ></Quote>
            <ViewParagraph
              content={data.posgradosOffer.data.attributes.Descripcion2}
            ></ViewParagraph>
            <Quote
              content={data.posgradosOffer.data.attributes.Atributo2}
            ></Quote>
            <ViewParagraph
              content={data.posgradosOffer.data.attributes.Descripcion3}
            ></ViewParagraph>
          </div>

          <div>
            <div className="formutpl">
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.4,
                  },
                }}
              >
                {Hubspotutpl(data.posgradosOffer.data.attributes.HubspotID)}
              </motion.div>
            </div>
          </div>
        </div>
        <ModalFixed
          color="#4d89b4"
          title="Estudia Derecho mención Derecho Procesal 📸"
          content={
            <>
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/9leHFOoAZ1A?si=a5tHjOIYAyL0uF7c"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </>
          }
        ></ModalFixed>
      </div>

      <div className="layout8 padding">
        <center>
          <h2>
            Plan de <b>estudios</b>
          </h2>
        </center>

        <Accordion
          title="Malla académica"
          content={
            <>
              <ViewParagraph
                content={data.posgradosOffer.data.attributes.Malla}
              ></ViewParagraph>
            </>
          }
        ></Accordion>

        <Accordion
          title="Brochure"
          content={
            <>
              {ViewBrochure(data.posgradosOffer.data.attributes.Brochure.data)}
            </>
          }
        ></Accordion>
      </div>

      <div className="graybg">
        <PosgradoPagos></PosgradoPagos>
      </div>

      <BlockLeftImage
        src={ViewPhoto(
          data.posgradosOffer.data.attributes.Accesosysalidasprofesionales.data
            .attributes.url
        )}
        content={
          <>
            {" "}
            <center>
              <h2>
                Accesos y <b>salidas profesionales</b>{" "}
              </h2>
            </center>
            <Accordion
              title="Perfil de ingreso"
              content={
                <>
                  <ViewParagraph
                    content={data.posgradosOffer.data.attributes.Perfilingreso}
                  ></ViewParagraph>
                </>
              }
            ></Accordion>
            <Accordion
              title="Perfil profesional"
              content={
                <>
                  <ViewParagraph
                    content={
                      data.posgradosOffer.data.attributes.Perfilprofesional
                    }
                  ></ViewParagraph>
                </>
              }
            ></Accordion>
            <Accordion
              title="Campo ocupacional"
              content={
                <>
                  <ViewParagraph
                    content={
                      data.posgradosOffer.data.attributes.Campoocupacional
                    }
                  ></ViewParagraph>
                </>
              }
            ></Accordion>
          </>
        }
      ></BlockLeftImage>

      <Docentes
        content={data.posgradosOffer.data.attributes.docentes}
      ></Docentes>
    </div>
  );
}

export default MaestriaDerechoProcesal;
