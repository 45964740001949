import useContentful from "../../hooks/use-contenful";
import Flipcard from "../../components/Flipcard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const query = `
query 
{
    videoTemplate(id: "5pZH8wzc3MwDf8gr0Mmsll") {
      contentBlock1{json}
      imageBlock1{url, title}
      contentBlock2{json}
      imageBlock2{url, title}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}

      contentBlock7{json}
  
     
    }
  
}
`;

function Cogobierno() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
   

      <div className="gobernanza-autoridades padding2">
        <div className="layout8">  <h2><b>Cogobierno</b></h2></div>
    
        <div className="layout8">
       
          <div className="flex">
            <Flipcard
              src={data.videoTemplate.imageBlock1.url}
              alt={data.videoTemplate.imageBlock1.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock1.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock2.url}
              alt={data.videoTemplate.imageBlock2.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock2.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock3.url}
              alt={data.videoTemplate.imageBlock3.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock3.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock4.url}
              alt={data.videoTemplate.imageBlock4.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock4.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock5.url}
              alt={data.videoTemplate.imageBlock5.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock5.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock6.url}
              alt={data.videoTemplate.imageBlock6.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock6.json
              )}
            ></Flipcard>
            <Flipcard
              content={documentToReactComponents(
                data.videoTemplate.contentBlock7.json
              )}
            ></Flipcard>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cogobierno;
