
import useContentful from "../../hooks/use-contenful";
import Flipcard from "../../components/Flipcard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const query = `
query 
{
    videoTemplate(id: "3vbPqhXQhwN5zhQSjzRS0U") {
      contentBlock1{json}
      contentBlock2{json}
      imageBlock3{url, title}
      contentBlock3{json}
      imageBlock4{url, title}
      contentBlock4{json}
      imageBlock5{url, title}
      contentBlock5{json}
      imageBlock6{url, title}
      contentBlock6{json}
      imageBlock7{url, title}
      contentBlock7{json}
      imageBlock8{url, title}
      contentBlock8{json}
      imageBlock9{url, title}
      contentBlock9{json}
      
     
    }
  
}
`;

function ConsejoSuperior() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="layout6">
        <center>
          {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
        </center>
      </div>

      <div className="gobernanza-autoridades">
        <div className="layout8">
          {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
          <div className="flex">
            <Flipcard
              src={data.videoTemplate.imageBlock3.url}
              alt={data.videoTemplate.imageBlock3.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock3.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock4.url}
              alt={data.videoTemplate.imageBlock4.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock4.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock5.url}
              alt={data.videoTemplate.imageBlock5.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock5.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock6.url}
              alt={data.videoTemplate.imageBlock6.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock6.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock7.url}
              alt={data.videoTemplate.imageBlock7.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock7.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock8.url}
              alt={data.videoTemplate.imageBlock8.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock8.json
              )}
            ></Flipcard>
            <Flipcard
              src={data.videoTemplate.imageBlock9.url}
              alt={data.videoTemplate.imageBlock9.title}
              content={documentToReactComponents(
                data.videoTemplate.contentBlock9.json
              )}
            ></Flipcard>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConsejoSuperior;
