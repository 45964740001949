import { NavLink } from "react-router-dom";
import Accordion from "../components/Accordion";
import BannerGeneral from "../components/BannerGeneral";
import BlockLeftImage from "../components/BlockLeftImage";
import BlockRightImage from "../components/BlockRightImage";
import Breadcumbs from "../components/Breadcumbs";
import ModalFixed from "../components/ModalFixed";
import useContentful from "../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Block3Columns from "../components/Block3Columns";

const query = `
query 
{
    videoTemplate(id: "1UT6xKJV7IXUlZuMipTOAE") {
        video{url }
      imageBlock1{url, title}
      contentBlock1{json}
      imageBlock2{url, title}
      imageBlock4{url, title}
      imageBlock5{url, title}
      imageBlock6{url, title}
      contentBlock2{json}

      contentBlock3{json}
      contentBlock4{json}
      contentBlock5{json}
      contentBlock6{json}
      contentBlock7{json}
      contentBlock8{json}
      contentBlock9{json}
      contentBlock10{json}
      contentBlock11{json}
      contentBlock12{json}
      contentBlock13{json}
     
    }
}
`;
function ModeloDistancia() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/en-linea">Modalidad en línea</NavLink>
              <span class="material-icons">chevron_right</span>
              <NavLink to="/estudiar-en-linea">Estudiar en línea</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <div className="relative">
        <div className="layout8   ">
          <h2 className="width500">
            ¿Por qué estudiar en la{" "}
            <b>modalidad a distancia y en línea en la UTPL?</b>
          </h2>
        </div>
        <ModalFixed
          title="¡Bienvenidos a la UTPL! 👋🏻"
          color="#4086b7"
          content={
            <>
              <iframe
                width="100%"
                height="500"
                allow="autoplay"
                src={data.videoTemplate.video.url}
              ></iframe>
            </>
          }
        ></ModalFixed>
      </div>
      <BlockLeftImage
        src={data.videoTemplate.imageBlock1.url}
        alt={data.videoTemplate.imageBlock1.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock1.json
        )}
      ></BlockLeftImage>
      <BlockRightImage
        src={data.videoTemplate.imageBlock2.url}
        alt={data.videoTemplate.imageBlock2.title}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock2.json
        )}
      ></BlockRightImage>
      <div >
        {" "}
        <BlockLeftImage
          src="https://images.ctfassets.net/112w21q1rvqr/1diM7KrILims1dX9t1mQZt/d2e6d9d82a55baba3de0e978b9e72d1e/jaguar.png"
          content={
            <>
              <h2>
                Perfil de <b>ingreso</b>
              </h2>

              <p>
                El estudiante de modalidad a distancia y en línea de la UTPL
                debe ser capaz de:
              </p>

              <ul>
                <li>
                  <p>Pensar rigurosamente</p>
                </li>
                <li>
                  <p> Comunicación efectiva</p>
                </li>
                <li>
                  <p>Razonar numéricamente</p>
                </li>
                <li>
                  <p>
                    Utilizar herramientas tecnológicas de forma reflexiva y
                    pragmática
                  </p>
                </li>
                <li>
                  <p>Comprender su realidad natural</p>
                </li>
                <li>
                  <p>
                    Conocer y valorar su historia y su realidad sociocultural
                  </p>
                </li>
                <li>
                  <p>
                    Actuar como ciudadano responsable para lograr su desarrollo
                    humano
                  </p>
                </li>
                <li>
                  <p>Manejar sus emociones en su interrelación social</p>
                </li>
                <li>
                  <p>Cuidar su salud y bienestar personal</p>
                </li>
                <li>
                  <p>Interés por la investigación para emprender e innovar</p>
                </li>
                <li>
                  <p>Aprender de forma continua</p>
                </li>
                <li>
                  <p>Solidaridad e implicación social</p>
                </li>
              </ul>
            </>
          }
        ></BlockLeftImage>
      </div>

      <center>
        <div className=" padding">
          <div className="width500">
            {documentToReactComponents(data.videoTemplate.contentBlock3.json)}
          </div>
          <div className="icon200 margin">
            <Block3Columns
              col1={
                <>
                  <img
                    src={data.videoTemplate.imageBlock4.url}
                    alt="utpl"
                  ></img>
                  <h3>Entorno virtual de aprendizaje</h3>
                  <p>
                    Plataforma que facilita la interacción educativa entre
                    profesores y estudiantes para potenciar el aprendizaje.
                  </p>

                  <a href="https://utpl.edu.ec/eva">Conócelo</a>
                </>
              }
              col2={
                <>
                  <img
                    src={data.videoTemplate.imageBlock5.url}
                    alt="utpl"
                  ></img>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock4.json
                  )}
                </>
              }
              col3={
                <>
                  <img
                    src={data.videoTemplate.imageBlock6.url}
                    alt="utpl"
                  ></img>
                  <h3>Biblioteca virtual</h3>
                  <p>
                    Más de 350 mil libros digitales de reconocidas editoriales,
                    están disponibles para nuestros estudiantes las 24 horas del
                    día, 7 días a la semana.
                  </p>
                  <a href="https://biblioteca.utpl.edu.ec/biblioteca-virtual">
                    Explorará
                  </a>
                </>
              }
            ></Block3Columns>
          </div>{" "}
        </div>
      </center>
      <div className=" padding">
        <div className="layout8">
          <h2 className="width500">
            Recursos de apoyo <b>para nuestros estudiantes</b>
          </h2>
          <p className="width500">
            Sabemos que nuestros estudiantes necesitan apoyo y orientación
            durante su vida universitaria y profesional. Por eso contamos con:
          </p>
          <br></br>

          <Accordion
            title="Curso de Introducción a la modalidad a distancia y en línea"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock5.json
            )}
          ></Accordion>
          <Accordion
            title="Jornada de orientación para la carrera"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock6.json
            )}
          ></Accordion>
          <Accordion
            title="Consejero estudiantil"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock7.json
            )}
          ></Accordion>
          <Accordion
            title="Prácticas preprofesionales"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock8.json
            )}
          ></Accordion>
          <Accordion
            title="Proyectos de vinculación"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock9.json
            )}
          ></Accordion>
          <Accordion
            title="Movilidad virtual"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock10.json
            )}
          ></Accordion>
          <Accordion
            title="Red UTPL Alumni"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock11.json
            )}
          ></Accordion>
          <Accordion
            title="Guía del estudiante"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock12.json
            )}
          ></Accordion>
          <Accordion
            title="Curso offline"
            content={documentToReactComponents(
              data.videoTemplate.contentBlock13.json
            )}
          ></Accordion>
        </div>
      </div>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default ModeloDistancia;
