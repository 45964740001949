import "../css/block-backgroundimage.css";
import { motion } from "framer-motion";

function BlockBackgroundImage(props) {
  const mystyle = {
    backgroundImage: `url(${props.src})`,
  };



  return (
    <>
      <div className="wrapper-blockbackgroundimage padding " style={mystyle}>
        <div className={"layout8 " + props.id}>
          <motion.div
            initial={{ y: 150, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                stiffness: 200,
              },
            }}
          >
            <div className="content">
              <div className="layout9">{props.content}</div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default BlockBackgroundImage;
