import "../css/blockherramientas.css";
import { motion } from "framer-motion";
import line1 from "../img/line1.png";
import line2 from "../img/line2.png";

function BlockHeramientas(props) {
  const mystyle = {
    backgroundColor: props.bgimage,
  };
  return (
    <div className="block-herramientas padding2 " id={props.style}>
      <div className="layout8">
        <div className="content-herramientas">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                stiffness: 200,
              },
            }}
          >
            {props.content}
          </motion.div>
        </div>
        <div className="block-herramientas-image">
          <img src={props.src} alt={props.alt}></img>

          <div className="bg-herramientas">
            <motion.div
              style={mystyle}
              initial={{ height: 0, opacity: 0 }}
              whileInView={{
                height: "100%",
                opacity: 1,

                transition: {
                  type: "spring",
                  damping: 50,
                  duration: 10,
                },
              }}
            ></motion.div>
          </div>
        </div>
      </div>

      <div className="bg-line2">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              duration: 1,
            },
          }}
        >
          <img className="line1" src={line1} alt="utpl"></img>
        </motion.div>
      </div>

      <div className="bg-line3">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              delay: 0.3,
              duration: 2,
            },
          }}
        >
          <img className="line2" src={line2} alt="utpl"></img>
        </motion.div>
      </div>
      <div className="bg-line4">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              delay: 0.6,
              duration: 2,
            },
          }}
        >
          <img className="line2" src={line2} alt="utpl"></img>
        </motion.div>
      </div>
    </div>
  );
}

export default BlockHeramientas;
